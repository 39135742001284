import React from "react";
import { observer } from "mobx-react-lite";
import StepProgress from "./components/StepProgress/StepProgress";
import { useAppState } from "../../../../AppState";
import { useGetProgressSteps } from "../../../../hooks/useGetProgressSteps";
import scss from "./styles.module.scss";

type Props = {
  currentStepId: number;
};

const ProgressSection = ({ currentStepId }: Props) => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const formattedSteps = useGetProgressSteps(assessmentStore.steps);

  return (
    <div className={scss.container}>
      {Object.keys(formattedSteps || {}).map((step, i) => {
        const percentage = formattedSteps[step].completedCount;
        const maxPercentage = formattedSteps[step].maxCount;
        const stepIds = formattedSteps[step].stepsIds;
        return (
          <div className={scss.progressItemWrapper} key={i}>
            {i ? <div className={scss.separator} /> : null}
            <StepProgress
              key={i}
              title={step}
              circleText={i + 1}
              percentage={percentage}
              maxPercentage={maxPercentage}
              completed={
                !!percentage && !!maxPercentage && percentage === maxPercentage
              }
              active={stepIds.includes(currentStepId)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default observer(ProgressSection);
