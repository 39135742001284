import React, { useEffect, useRef } from "react";
import Button from "../../../../../../components/shared/Button/Button";
import cn from "classnames";
import scss from "./styles.module.scss";

const CompletedSection = () => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn(scss.container, {
        [scss.show]: mount.current,
      })}
    >
      <div className={scss.textWrapper}>
        <h2 className={scss.title}>Well Done!</h2>
        <p className={scss.subtitle}>Results will be ready right away.</p>
        <Button
          variant="filled-primary"
          uppercase
          type="innerLink"
          to="/career-match"
        >
          Check the result
        </Button>
      </div>
      <div className={scss.imageWrapper}>
        <img src="/assets/assessment-completed.webp" alt="completed" />
      </div>
    </div>
  );
};

export default CompletedSection;
