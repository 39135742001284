import React from "react";
import cn from "classnames";
import CompletedSmallIcon from "../../../icons/CompletedSmallIcon";
import { AssessmentStepOptionDto } from "../../../api/data-contracts";
import { KeyCode } from "../../../utils/keyCode";
import scss from "./styles.module.scss";

type Props = {
  option: AssessmentStepOptionDto;
  isChecked: boolean;
  onChange: (optionId: string, value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

const Checkbox = ({
  option,
  isChecked,
  onChange,
  className,
  disabled,
}: Props) => {
  const onChangeHandler = () => {
    onChange(option.relationId, true);
  };

  const onKeyDownHandler: React.KeyboardEventHandler<HTMLLabelElement> = (
    e,
  ) => {
    if (e.keyCode === KeyCode.ENTER || e.keyCode === KeyCode.SPACE)
      onChangeHandler();
  };

  return (
    <label
      htmlFor={option.relationId}
      className={cn(
        scss.optionWrapper,
        { [scss.checked]: isChecked },
        { [scss.disabled]: disabled },
        className,
      )}
      tabIndex={1}
      onKeyUp={onKeyDownHandler}
    >
      <div className={scss.checkbox}>
        <CompletedSmallIcon className={scss.checkboxIcon} />
      </div>
      <input
        className={scss.originCheckbox}
        type="checkbox"
        id={option.relationId}
        tabIndex={0}
        checked={isChecked}
        onChange={onChangeHandler}
      />
      <span className={scss.title}>{option.title}</span>
      {option.icon ? (
        <div
          className={scss.icon}
          dangerouslySetInnerHTML={{ __html: option.icon }}
        />
      ) : null}
    </label>
  );
};

export default Checkbox;
