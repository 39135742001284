import { AppState } from "../AppState";
import { fetchMe } from "../api/api";

export class UserService {
  private root: AppState;

  constructor(root: AppState) {
    this.root = root;
  }

  public fetchMe = async () => {
    try {
      const token = this.root.service.authService.getToken();
      if (token) {
        const { data } = await fetchMe();
        this.root.store.userStore.setUser(data.user);
      }
    } catch (_) {
      this.root.store.userStore.clearUser();
    }
  };
}
