import React, { useState } from "react";
import Input from "../../../shared/Input/Input";
import EmailIcon from "../../../../icons/EmailIcon";
import PasswordIcon from "../../../../icons/PasswordIcon";
import Button from "../../../shared/Button/Button";
import isEmail from "validator/lib/isEmail";
import { validatePassword } from "../../../../utils/validatePassword";
import { useAppState } from "../../../../AppState";
import { AccountSteps } from "../../AccountModal";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
  currentStep: AccountSteps;
};

type StateProps = {
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
};

const LoginRegisterForm = ({ changeStepHandler, currentStep }: Props) => {
  const {
    service: { authService },
    store: { modalStore },
  } = useAppState();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<StateProps>({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });

  const isLoginStep = currentStep === "LOGIN";

  const submit = async () => {
    if (submitting) return;

    const formattedEmail = state.email.toLowerCase().trim();
    const validEmail = isEmail(formattedEmail);

    if (!validEmail) {
      setState((prevState) => ({ ...prevState, emailError: "Invalid email" }));
    }

    const formattedPassword = state.password.trim();
    const validPassword = validatePassword(formattedPassword);

    if (!validPassword) {
      setState((prevState) => ({
        ...prevState,
        passwordError: "Invalid password",
      }));
    }

    if (validEmail && validPassword) {
      setSubmitting(true);
      try {
        if (isLoginStep) {
          await authService.login({
            identifier: formattedEmail,
            password: formattedPassword,
          });
          modalStore.closeModal();
        } else {
          await authService.register({
            email: formattedEmail,
            password: formattedPassword,
          });
          changeStepHandler("SUCCESS")();
        }
      } catch (err: any) {
        const message =
          err?.response?.data?.error?.message ||
          err?.response?.data?.error?.details?.message;
        if (message) {
          setState((prevState) => ({
            ...prevState,
            emailError: message,
          }));
        }
      }

      setSubmitting(false);
    }
  };

  const changeStateHandler = (value: string, target: HTMLInputElement) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: value,
      ...(target.name.startsWith("email")
        ? { emailError: "" }
        : { passwordError: "" }),
    }));
  };

  return (
    <AccountModalInnerLayout
      title={isLoginStep ? "Welcome back" : "Create an account"}
    >
      <div className={scss.mainWrapper}>
        <Input
          value={state.email}
          onChange={changeStateHandler}
          placeholder="Enter your email..."
          errorText={state.emailError}
          icon={<EmailIcon />}
          name="email"
        />
        <Input
          value={state.password}
          onChange={changeStateHandler}
          placeholder="Password"
          errorText={state.passwordError}
          type="password"
          icon={<PasswordIcon />}
          name="password"
        />
        {isLoginStep && (
          <Button
            className={scss.forgotPassword}
            variant="outfilled"
            onClick={changeStepHandler("FORGOT_PASSWORD")}
          >
            Forgot password?
          </Button>
        )}
        <Button
          onClick={submit}
          className={scss.button}
          type="button"
          uppercase
          loading={submitting}
          variant="filled-primary"
        >
          {isLoginStep ? "Sign in" : "Sign up"}
        </Button>
      </div>
      <div className={scss.loginFooter}>
        <p className={scss.footerText}>
          {isLoginStep ? "Don't have an account?" : "Already have an account?"}
        </p>
        <Button
          variant="outfilled"
          uppercase
          onClick={changeStepHandler(isLoginStep ? "REGISTRATION" : "LOGIN")}
        >
          {isLoginStep ? "Sign up" : "Sign in"}
        </Button>
      </div>
    </AccountModalInnerLayout>
  );
};

export default LoginRegisterForm;
