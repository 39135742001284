import React from "react";
import scss from "./styles.module.scss";

const links: Array<{ title: string; url: string }> = [
  {
    url: "https://guidenar.com/privacy-policy",
    title: "Privacy Policy",
  },
  {
    url: "https://guidenar.com/terms-of-use",
    title: "Terms of Use",
  },
  {
    url: "https://guidenar.com/user-agreement",
    title: "User Agreement",
  },
  {
    url: "https://guidenar.com/cookie-policy",
    title: "Cookie Policy",
  },
  {
    url: "https://guidenar.com/refund-policy",
    title: "Refund Policy",
  },
  {
    url: "https://guidenar.com/copyright-notice",
    title: "Copyright Notice",
  },
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className={scss.wrapper}>
      <div className={scss.innerWrapper}>
        {/*<div className={scss.topSection}>*/}
        {/*  <div className={scss.logoSection}>*/}
        {/*    <Logo />*/}
        {/*    <div className={scss.separator} />*/}
        {/*    <p className={scss.footerText}>*/}
        {/*      Navigate to a career where <br /> your talents are unleashed*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <div className={scss.buttonsSection}>*/}
        {/*    <Button*/}
        {/*      className={scss.button}*/}
        {/*      type="link"*/}
        {/*      variant="outline"*/}
        {/*      uppercase*/}
        {/*      href={""}*/}
        {/*    >*/}
        {/*      sign in*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      className={scss.button}*/}
        {/*      type="link"*/}
        {/*      variant="filled-primary"*/}
        {/*      uppercase*/}
        {/*      href={""}*/}
        {/*    >*/}
        {/*      Get started*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={scss.policiesSection}>
          {links.map((item, i) => (
            <a className={scss.linkItem} href={item.url} key={i}>
              {item.title}
            </a>
          ))}
        </div>
        <div className={scss.bottomSection}>
          All Right Reserved © Copyright {year}
        </div>
      </div>
    </div>
  );
};

export default Footer;
