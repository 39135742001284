import React from "react";
import { CareerInfoDto } from "../../../../api/data-contracts";
import CareerInfo from "../CareerMatchMainSection/components/CareerInfo/CareerInfo";
import { useAppState } from "../../../../AppState";
import CloseIcon from "../../../../icons/CloseIcon";
import scss from "./styles.module.scss";

type Props = {
  career: CareerInfoDto;
};

const CareerInfoModal = ({ career }: Props) => {
  const {
    store: { modalStore },
  } = useAppState();
  return (
    <div className={scss.container}>
      <h2 className={scss.title}>{career.title}</h2>
      <div className={scss.closeIcon} onClick={modalStore.closeModal}>
        <CloseIcon />
      </div>
      <div className={scss.careerInfoWrapper}>
        <CareerInfo career={career} className={scss.careerInfo} titleHidden />
      </div>
    </div>
  );
};

export default CareerInfoModal;
