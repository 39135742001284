import { memo, SVGProps } from "react";

const CompletedSmallIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      {...props}
    >
      <path
        d="M2 7.00002L7.33371 12L18 2"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(CompletedSmallIcon);
