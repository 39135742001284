import React, { useState } from "react";
import { ReviewDto } from "../../../../api/data-contracts";
import ReviewCard from "./components/ReviewCard/ReviewCard";
import ArrowShort from "../../../../icons/ArrowShort";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../AppState";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  reviews: ReviewDto[];
};

const ReviewsSection = ({ reviews }: Props) => {
  const {
    store: { resolutionStore },
  } = useAppState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState(false);
  const isMobileOrTablet = resolutionStore.isMobileOrTablet;
  const { desktop, desktopLg } = resolutionStore.resolution;
  const withScroll = desktopLg || desktop || isMobileOrTablet;

  const nextPrevReviewHandler =
    (index: number, direction: "next" | "prev") => () => {
      if (direction === "next") {
        setAnimation(true);
        setTimeout(() => {
          setActiveIndex(index >= reviews.length ? 0 : index);
          setAnimation(false);
        }, 400);
      } else {
        setAnimation(true);
        setTimeout(() => {
          setActiveIndex(index < 0 ? reviews.length - 1 : index);
          setAnimation(false);
        }, 400);
      }
    };
  return (
    <div className={scss.container}>
      <div className={scss.infoWrapper}>
        <p className={scss.caption}>clients reviews</p>
        <h2 className={scss.title}>What clients think about our services</h2>
        <p className={scss.description}>
          At Guidenar, we are committed to providing our clients with the tools
          they need to discover and achieve their career goals. Hear what our
          clients have to say about how we have helped them.
        </p>
        <div className={scss.actionsWrapper}>
          <div
            className={scss.prevButton}
            onClick={nextPrevReviewHandler(activeIndex - 1, "prev")}
          >
            <ArrowShort />
          </div>
          <div
            className={scss.nextButton}
            onClick={nextPrevReviewHandler(activeIndex + 1, "next")}
          >
            <ArrowShort />
          </div>
        </div>
      </div>
      <div className={cn(scss.reviewsWrapper, "hiddenScroll")}>
        {withScroll ? (
          reviews.map((review) => (
            <ReviewCard
              className={animation ? scss.hide : undefined}
              card={review}
              key={review.id}
              mountWithAnimate
            />
          ))
        ) : (
          <ReviewCard
            className={animation ? scss.hide : undefined}
            card={reviews[activeIndex]}
            key={reviews[activeIndex].id}
            mountWithAnimate
          />
        )}
      </div>
    </div>
  );
};

export default observer(ReviewsSection);
