import React from "react";
import CircleProgressBar from "../../../../../../components/shared/CircleProgressBar/CircleProgressBar";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  circleText: string | number;
  active?: boolean;
  completed?: boolean;
  percentage: number;
  maxPercentage: number;
};

const StepProgress = ({
  circleText,
  title,
  active,
  completed,
  percentage,
  maxPercentage,
}: Props) => {
  return (
    <div className={scss.container}>
      <CircleProgressBar
        percentage={percentage}
        maxPercentage={maxPercentage}
        circleDiameter={56}
        strokeWidth={8}
        radius={24}
        title={circleText}
        active={active}
      />
      <div
        className={cn(scss.titleWrapper, {
          [scss.active]: active,
          [scss.completed]: completed,
        })}
      >
        <p className={scss.title}>{title}</p>
      </div>
    </div>
  );
};

export default StepProgress;
