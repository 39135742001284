import React from "react";
import Caption from "../../../../components/shared/Caption/Caption";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  personalities: string[];
};

const CareerMatchTopSection = ({ personalities }: Props) => {
  return (
    <div className={scss.container}>
      <p className={scss.title}>Career Matches</p>
      <div className={cn(scss.personalitiesWrapper, "hiddenScroll")}>
        <p className={scss.personalitiesTitle}>Personal type:</p>
        <div className={scss.personalitiesInnerWrapper}>
          {personalities.map((el, i) => (
            <Caption variant="green" title={el} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerMatchTopSection;
