import React, { useState } from "react";
import Button from "../../../../components/shared/Button/Button";
import ArrowLong from "../../../../icons/ArrowLong";
import { useAppState } from "../../../../AppState";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AssessmentStepCategoryDto } from "../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  setActiveStepIndex: (stepIndex: number) => void;
  currentStepIndex: number;
  maxSteps: number;
  stepCategory: AssessmentStepCategoryDto;
};

const ActionsSection = ({
  currentStepIndex,
  setActiveStepIndex,
  maxSteps,
  stepCategory,
}: Props) => {
  const {
    store: { assessmentStore },
    service: { assessmentService },
  } = useAppState();

  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const isLastStep = currentStepIndex > 1 && maxSteps === currentStepIndex + 1;

  const isEmptyState = !Object.keys(
    assessmentStore.steps[currentStepIndex]?.selectedOptions || {},
  ).length;

  const disabledSubmitButton =
    [
      "INDUSTRY_SELECT_INDUSTRIES",
      "PERSONALITY",
      "VALUES",
      "SUBJECTS",
    ].includes(stepCategory) && isEmptyState;

  const nextStepHandler = async () => {
    setSubmitting(true);
    const data = await assessmentService.updateAssessmentById(
      id as string,
      currentStepIndex,
    );
    const initStepIndex = data?.steps.findIndex((step) => !step.completed);
    setSubmitting(false);
    if (initStepIndex) {
      setActiveStepIndex(
        initStepIndex < currentStepIndex ? initStepIndex : currentStepIndex + 1,
      );
    } else setActiveStepIndex(currentStepIndex + 1);
  };

  const prevStepHandler = async () => {
    setActiveStepIndex(currentStepIndex - 1);
  };

  return (
    <div className={scss.container}>
      <Button
        variant="outline"
        uppercase
        className={scss.button}
        disabled={currentStepIndex === 0}
        onClick={prevStepHandler}
      >
        <ArrowLong className={scss.icon} />
        <span>Back</span>
      </Button>
      <Button
        variant="filled-primary"
        uppercase
        className={scss.buttonGreen}
        onClick={nextStepHandler}
        loading={submitting}
        disabled={disabledSubmitButton}
      >
        {isLastStep ? "Finish" : "Next"}
        <ArrowLong />
      </Button>
    </div>
  );
};

export default observer(ActionsSection);
