import React, { useEffect, useState } from "react";
import { useAppState } from "../../AppState";
import { observer } from "mobx-react-lite";
import CloseIcon from "../../icons/CloseIcon";
import QuickLoginRegisterForm from "./components/QuickLoginRegisterForm/QuickLoginRegisterForm";
import Preloader from "../../icons/Preloader";
import PaymentForm from "./components/PaymentForm/PaymentForm";
import { CareerMatchDto } from "../../api/data-contracts";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import ContinueAssessment from "./components/ContinueAssessment/ContinueAssessment";
import cn from "classnames";
import scss from "./styles.module.scss";

export type QuickStartModalSteps =
  | "REGISTRATION"
  | "LOGIN"
  | "PAYMENT"
  | "PAYMENT_SUCCESS"
  | "PAYMENT_ERROR"
  | "CONTINUE_ASSESSMENT";

type Props = {
  isPaymentSuccess?: boolean;
  isPaymentPending?: boolean;
};

const QuickStartModal = ({ isPaymentSuccess, isPaymentPending }: Props) => {
  const {
    store: { userStore, modalStore },
    service: { assessmentService },
  } = useAppState();
  const user = userStore.user;
  const isAuth = userStore.isAuth;

  const [step, setStep] = useState<QuickStartModalSteps>();
  const [activeCareerMatch, setActiveCareerMatch] = useState<CareerMatchDto>();

  useEffect(() => {
    setTimeout(() => {
      if (!isAuth) setStep("REGISTRATION");
      else {
        if (user.activeAssessmentId) {
          assessmentService
            .createOrGetActiveCareerMatch()
            .then((careerMatch) => {
              setActiveCareerMatch(careerMatch);
              setStep(
                careerMatch.paymentStatus === "PAID"
                  ? isPaymentSuccess
                    ? "PAYMENT_SUCCESS"
                    : "CONTINUE_ASSESSMENT"
                  : "PAYMENT",
              );
            });
        } else {
          assessmentService
            .createOrGetActiveCareerMatch()
            .then((careerMatch) => {
              setActiveCareerMatch(careerMatch);
              setStep("PAYMENT");
            });
        }
      }
    }, 250);
  }, [assessmentService, isAuth, isPaymentSuccess, user]);

  const isPending =
    isPaymentPending || activeCareerMatch?.paymentStatus === "PENDING";

  return (
    <div className={cn(scss.container, scss[step as QuickStartModalSteps])}>
      <CloseIcon className={scss.closeIcon} onClick={modalStore.closeModal} />
      {step === "REGISTRATION" || step === "LOGIN" ? (
        <QuickLoginRegisterForm
          key={step}
          currentStep={step}
          setStep={setStep}
        />
      ) : step === "PAYMENT" ? (
        <PaymentForm
          setStep={setStep}
          activeCareerMatch={activeCareerMatch as CareerMatchDto}
          isPending={isPending}
        />
      ) : step === "PAYMENT_SUCCESS" ? (
        <PaymentSuccess
          activeCareerMatch={activeCareerMatch as CareerMatchDto}
        />
      ) : step === "CONTINUE_ASSESSMENT" ? (
        <ContinueAssessment
          activeCareerMatch={activeCareerMatch as CareerMatchDto}
        />
      ) : (
        <div className={scss.preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
};

export default observer(QuickStartModal);
