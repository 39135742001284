import React from "react";
import cn from "classnames";
import CompletedSmallIcon from "../../../../icons/CompletedSmallIcon";
import { createArrayFromNumber } from "../../../../utils/arrayUtils";
import scss from "./styles.module.scss";

type Props = {
  stepNumber: number;
  maxSteps: number;
  className?: string;
};

const QuickStartProgress = ({ stepNumber, maxSteps, className }: Props) => {
  const arr = createArrayFromNumber(maxSteps);
  return (
    <div className={cn(scss.container, className)}>
      {arr.map((el, i) =>
        i ? (
          <div key={i} className={scss.stepItemWrapper}>
            <div className={scss.separator} />
            <div
              className={cn(scss.stepItem, {
                [scss.completed]: stepNumber > el,
                [scss.active]: stepNumber === el,
              })}
            >
              {stepNumber > el ? <CompletedSmallIcon /> : el}
            </div>
          </div>
        ) : (
          <div
            key={i}
            className={cn(scss.stepItem, {
              [scss.completed]: stepNumber > el,
              [scss.active]: stepNumber === el,
            })}
          >
            {stepNumber > el ? <CompletedSmallIcon /> : el}
          </div>
        ),
      )}
    </div>
  );
};

export default QuickStartProgress;
