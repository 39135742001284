import { action, computed, makeObservable, observable } from "mobx";
import { ReactNode } from "react";

type Options = {
  overlayColor?: string;
  overlayClassName?: string;
  modalClassName?: string;
  preventCloseByRouteChange?: boolean;
};

export class ModalStore {
  opened: boolean = false;
  content: ReactNode = null;
  options: Options = { preventCloseByRouteChange: false };

  constructor() {
    makeObservable(this, {
      opened: observable,
      openModal: action,
      closeModal: action,
      state: computed,
    });
  }

  openModal = (content: ReactNode, options: Options) => {
    this.opened = true;
    this.content = content;
    this.options = { preventCloseByRouteChange: false, ...options };
  };

  closeModal = () => {
    this.opened = false;
    this.content = null;
  };

  get state() {
    return {
      opened: this.opened,
      content: this.content,
      ...this.options,
    };
  }
}
