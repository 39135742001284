import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

const captions = [
  { title: "1000+", description: "Early careers from different industries" },
  {
    title: "9 mins",
    description: "AVG time to complete an assessment on the platform",
  },
];

type Props = {
  className?: string;
};

const CardsWithCaptions = ({ className }: Props) => {
  return (
    <div className={cn(scss.container, className)}>
      <div className={scss.imagesWrapper}>
        <img
          className={cn(scss.image, scss.image1)}
          src="/assets/result-card-1.webp"
          alt="card1"
        />
        <img
          className={cn(scss.image, scss.image2)}
          src="/assets/result-card-2.webp"
          alt="card2"
        />
        <img
          className={cn(scss.image, scss.image3)}
          src="/assets/result-card-3.webp"
          alt="card3"
        />
      </div>
      <div className={scss.captionsWrapper}>
        {captions.map((el, i) => (
          <div className={scss.captionWrapper} key={i}>
            <p className={scss.captionTitle}>{el.title}</p>
            <p className={scss.captionDescription}>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsWithCaptions;
