import React, { useState } from "react";
import QuickStartModalInnerLayout from "../QuickStartModalInnerLayout/QuickStartModalInnerLayout";
import { QuickStartModalSteps } from "../../QuickStartModal";
import { useAppState } from "../../../../AppState";
import isEmail from "validator/lib/isEmail";
import { validatePassword } from "../../../../utils/validatePassword";
import Input from "../../../shared/Input/Input";
import EmailIcon from "../../../../icons/EmailIcon";
import PasswordIcon from "../../../../icons/PasswordIcon";
import Button from "../../../shared/Button/Button";
import scss from "./styles.module.scss";

type Props = {
  currentStep: QuickStartModalSteps;
  setStep: (step: QuickStartModalSteps) => void;
};

type StateProps = {
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
};

const QuickLoginRegisterForm = ({ currentStep, setStep }: Props) => {
  const {
    service: { authService },
  } = useAppState();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<StateProps>({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });

  const isLoginStep = currentStep === "LOGIN";

  const changeStepHandler = (step: QuickStartModalSteps) => () => {
    setStep(step);
  };

  const submit = async () => {
    if (submitting) return;

    const formattedEmail = state.email.toLowerCase().trim();
    const validEmail = isEmail(formattedEmail);

    if (!validEmail) {
      setState((prevState) => ({ ...prevState, emailError: "Invalid email" }));
    }

    const formattedPassword = state.password.trim();
    const validPassword = validatePassword(formattedPassword);

    if (!validPassword) {
      setState((prevState) => ({
        ...prevState,
        passwordError: "Invalid password",
      }));
    }

    if (validEmail && validPassword) {
      setSubmitting(true);
      try {
        if (isLoginStep) {
          await authService.login({
            identifier: formattedEmail,
            password: formattedPassword,
          });
        } else {
          await authService.register({
            email: formattedEmail,
            password: formattedPassword,
          });
        }
      } catch (err: any) {
        const message =
          err?.response?.data?.error?.message ||
          err?.response?.data?.error?.details?.message;
        if (message) {
          setState((prevState) => ({
            ...prevState,
            emailError: message,
          }));
        }
      }

      setSubmitting(false);
    }
  };

  const changeStateHandler = (value: string, target: HTMLInputElement) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: value,
      ...(target.name.startsWith("email")
        ? { emailError: "" }
        : { passwordError: "" }),
    }));
  };
  return (
    <QuickStartModalInnerLayout
      title={
        currentStep === "REGISTRATION" ? "Quick registration" : "Welcome back"
      }
      subtitle={
        currentStep === "REGISTRATION"
          ? "Let's create a profile together to store all the insights from" +
            " your assessment results."
          : "Log in to your profile to proceed to the next step."
      }
      progress={{ currentStep: 1, maxSteps: 2 }}
      innerClassName={scss.container}
    >
      <div className={scss.inputsWrapper}>
        <Input
          value={state.email}
          onChange={changeStateHandler}
          placeholder="Enter your email..."
          errorText={state.emailError}
          icon={<EmailIcon />}
          name="email"
        />
        <Input
          value={state.password}
          onChange={changeStateHandler}
          placeholder="Password"
          errorText={state.passwordError}
          type="password"
          icon={<PasswordIcon />}
          name="password"
        />
      </div>
      <Button
        loading={submitting}
        className={scss.button}
        variant="filled-primary"
        uppercase
        onClick={submit}
      >
        Next step
      </Button>
      <div className={scss.loginFooter}>
        <p className={scss.footerText}>
          {isLoginStep ? "Don't have an account?" : "Already have an account?"}
        </p>
        <Button
          variant="outfilled"
          uppercase
          onClick={changeStepHandler(isLoginStep ? "REGISTRATION" : "LOGIN")}
        >
          {isLoginStep ? "Sign up" : "Sign in"}
        </Button>
      </div>
    </QuickStartModalInnerLayout>
  );
};

export default QuickLoginRegisterForm;
