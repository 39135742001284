import React, { ReactNode, useEffect, useRef } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

const ActionButtonsWrapper = ({ children }: Props) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div
      ref={ref}
      className={cn(scss.container, {
        [scss.show]: mount.current,
      })}
    >
      {children}
    </div>
  );
};

export default ActionButtonsWrapper;
