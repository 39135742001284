import React from "react";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const NotFound = () => {
  return (
    <div className={scss.pageContainer}>
      <div className={scss.container}>
        <div className={scss.imageWrapper}>
          <img src="/assets/not-found.webp" alt="Not Found" />
        </div>
        <h1 className={scss.title}>oops... something went wrong</h1>
        <p className={scss.subtitle}>
          Click on the button below to return home
        </p>
        <Button type="innerLink" to="/" variant="filled-primary" uppercase>
          go home page
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
