import React, { useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import Button from "../shared/Button/Button";
import cn from "classnames";
import ArrowShort from "../../icons/ArrowShort";
import YouTubeVideoBlock from "../YouTubeVideoBlock/YouTubeVideoBlock";
import CloseIcon from "../../icons/CloseIcon";
import { useAppState } from "../../AppState";
import scss from "./styles.module.scss";

type Props = {
  videos: string[];
  activeIndex: number;
};

const VideoModal = ({ videos, activeIndex }: Props) => {
  const {
    store: { modalStore },
  } = useAppState();
  const swiperRef = useRef<SwiperRef | null>(null);

  const [activeVideoIndex, setActiveVideoIndex] = useState(activeIndex);

  const prevNextHandler = (direction: "prev" | "next") => {
    if (direction === "next") {
      setActiveVideoIndex((prevState) =>
        prevState === videos.length - 1 ? 0 : prevState + 1,
      );
    } else {
      setActiveVideoIndex((prevState) =>
        prevState === 0 ? videos.length - 1 : prevState - 1,
      );
    }
  };

  const swiperNextSlide = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const swiperPrevSlide = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  return (
    <div className={scss.container}>
      <h3 className={scss.title}>Our presentation videos</h3>
      <CloseIcon className={scss.closeIcon} onClick={modalStore.closeModal} />
      <div className={scss.swiperWrapper}>
        <Swiper
          onSlideNextTransitionEnd={() => prevNextHandler("next")}
          onSlidePrevTransitionEnd={() => prevNextHandler("prev")}
          ref={swiperRef}
          initialSlide={activeVideoIndex}
          slidesPerView={1}
          spaceBetween={24}
          loop={true}
        >
          {videos.map((item, i) => (
            <SwiperSlide key={item}>
              <YouTubeVideoBlock
                id={item}
                isActive={i === activeVideoIndex}
                onEnd={swiperNextSlide}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={scss.controlsWrapper}>
        <Button
          className={cn(scss.button, scss.prevButton)}
          onClick={swiperPrevSlide}
        >
          <ArrowShort />
        </Button>
        <Button className={scss.button} onClick={swiperNextSlide}>
          <ArrowShort />
        </Button>
      </div>
    </div>
  );
};

export default VideoModal;
