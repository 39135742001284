import React, { ReactNode } from "react";
import PaymentIconVisa from "../../../../../../icons/PaymentIconVisa";
import PaymentIconMasterCard from "../../../../../../icons/PaymentIconMasterCard";
import PaymentIconAmericanExpress from "../../../../../../icons/PaymentIconAmericanExpress";
import PaymentIconPayPal from "../../../../../../icons/PaymentIconPayPal";
import cn from "classnames";
import { PaymentMethodDto } from "../../../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  isActive: boolean;
  onClick?: React.MouseEventHandler;
  paymentMethod: PaymentMethodDto;
  disabled?: boolean;
};

const PaymentMethod = ({
  isActive,
  onClick,
  paymentMethod,
  disabled,
}: Props) => {
  const icons: ReactNode[] =
    paymentMethod === "CARD"
      ? [
          <PaymentIconVisa />,
          <PaymentIconMasterCard />,
          <PaymentIconAmericanExpress />,
        ]
      : [<PaymentIconPayPal />];

  const title = paymentMethod === "CARD" ? "Bank Card" : "PayPal";

  return (
    <button
      className={cn(scss.button, {
        [scss.active]: isActive,
        [scss.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <div className={scss.dot}>
        <div className={scss.dotInner} />
      </div>
      <p className={scss.title}>{title}</p>
      <div className={scss.iconsWrapper}>
        {icons.map((icon, i) =>
          i ? (
            <div key={i} className={scss.iconInnerWrapper}>
              <div className={scss.separator} />
              {icon}
            </div>
          ) : (
            <div key={i} className={scss.iconInnerWrapper}>
              {icon}
            </div>
          ),
        )}
      </div>
    </button>
  );
};

export default PaymentMethod;
