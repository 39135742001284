import { GlobalStore } from "./store/GlobalStore";
import { GlobalService } from "./service/GlobalService";
import React, { ReactNode } from "react";

export class AppState {
  store: GlobalStore;
  service: GlobalService;

  constructor() {
    this.store = new GlobalStore();
    this.service = new GlobalService(this);
  }
}

const appState = new AppState();

export default appState;

const AppContext = React.createContext<AppState>(appState);

export const AppStateProvider = (props: { children: ReactNode }) => {
  return (
    <AppContext.Provider value={appState}>{props.children}</AppContext.Provider>
  );
};

export const useAppState = () => {
  return React.useContext(AppContext);
};
