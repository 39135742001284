import React from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import ErrorBigIcon from "../../../../icons/ErrorBigIcon";
import Button from "../../../shared/Button/Button";
import { AccountSteps } from "../../AccountModal";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
};

const InvalidTokenMessage = ({ changeStepHandler }: Props) => {
  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title="something went wrong"
      subtitle="The password reset link appears to be out of date or invalid. Please send your request again"
      subtitleMaxWidth={324}
    >
      <ErrorBigIcon />
      <Button
        className={scss.button}
        uppercase
        variant="filled-primary"
        onClick={changeStepHandler("FORGOT_PASSWORD")}
      >
        Try again
      </Button>
    </AccountModalInnerLayout>
  );
};

export default InvalidTokenMessage;
