import React, { useCallback } from "react";
import { AssessmentStepDto } from "../../../../../../api/data-contracts";
import QuestionSectionLayout from "../QuestionSectionLayout/QuestionSectionLayout";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../../../AppState";
import Checkbox from "../../../../../../components/shared/Checkbox/Checkbox";
import scss from "./styles.module.scss";

type Props = {
  step: AssessmentStepDto;
  currentStepIndex: number;
};

const SelectPersonalityQuestion = ({ currentStepIndex, step }: Props) => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const onChangeHandler = useCallback(
    (optionId: string, value: boolean) => {
      assessmentStore.setStepSelectionOptions(currentStepIndex, {
        [optionId]: { selected: value },
      });
    },
    [currentStepIndex, assessmentStore],
  );

  return (
    <QuestionSectionLayout
      title={step.questionTitle}
      innerClassName={scss.container}
      withSeparator
    >
      {step.options.map((option) => (
        <Checkbox
          className={scss.checkbox}
          key={option.relationId}
          option={option}
          onChange={onChangeHandler}
          isChecked={!!step.selectedOptions?.[option.relationId]}
        />
      ))}
    </QuestionSectionLayout>
  );
};

export default observer(SelectPersonalityQuestion);
