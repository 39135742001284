import React from "react";
import CareerFeatureItem, { CareerFeatureDto } from "./CareerFeatureItem";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  features: CareerFeatureDto[];
  className?: string;
};

const CareerFeatures = ({ features, className }: Props) => {
  return (
    <div className={cn(scss.container, className)}>
      <p className={scss.title}>Features</p>
      <div className={scss.featuresWrapper}>
        {features.map((el, i) => (
          <CareerFeatureItem
            title={el.title}
            description={el.description}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default CareerFeatures;
