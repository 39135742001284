import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  paddings: "s" | "m";
  className?: string;
  variant?: "orange" | "green";
};

const Caption = ({ paddings, className, title, variant = "orange" }: Props) => {
  return (
    <span
      className={cn(
        scss.caption,
        scss[`paddings-${paddings}`],
        scss[variant],
        className,
      )}
    >
      {title}
    </span>
  );
};

Caption.defaultProps = {
  paddings: "m",
};

export default Caption;
