import React from "react";
import cn from "classnames";
import PaymentCard from "../PaymentCard/PaymentCard";
import { PaymentDto } from "../../../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  payments: PaymentDto[];
};

const PaymentsHistory = ({ payments }: Props) => {
  return (
    <div className={scss.paymentsWrapper}>
      <p className={scss.paymentsTitle}>Recent Payments</p>
      <div className={cn(scss.paymentsInnerWrapper)}>
        {payments.length ? (
          payments.map((payment) => (
            <PaymentCard payment={payment} key={payment.id} />
          ))
        ) : (
          <p className={scss.paymentsPlaceholder}>No payments yet</p>
        )}
      </div>
    </div>
  );
};

export default PaymentsHistory;
