import React, { useEffect, useMemo, useState } from "react";
import { CareerInfoDto } from "../../../../api/data-contracts";
import CareersGroup from "./components/CareersGroup/CareersGroup";
import { getCareersShortInfo } from "../../../../utils/getCareersShortInfo";
import CareerInfo from "./components/CareerInfo/CareerInfo";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../AppState";
import CareerInfoModal from "../CareerInfoModal/CareerInfoModal";
import scss from "./styles.module.scss";

type Props = {
  careersFromSelectedIndustries: CareerInfoDto[];
  careersFromOtherIndustries: CareerInfoDto[];
};

export type CareerState = {
  groupIndex: number;
  careerIndex: number;
};

const CareerMatchMainSection = ({
  careersFromSelectedIndustries,
  careersFromOtherIndustries,
}: Props) => {
  const {
    store: { resolutionStore, modalStore },
  } = useAppState();

  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobile = phone || phoneLg || tablet;

  const [activeCareerState, setActiveCareerState] = useState<CareerState>({
    careerIndex: isMobile ? -1 : 0,
    groupIndex: isMobile ? -1 : 0,
  });

  useEffect(() => {
    if (isMobile) {
      if (
        activeCareerState.careerIndex !== -1 &&
        activeCareerState.groupIndex !== -1
      ) {
        setActiveCareerState({
          careerIndex: -1,
          groupIndex: -1,
        });
      }
    } else {
      if (
        activeCareerState.careerIndex === -1 &&
        activeCareerState.groupIndex === -1
      )
        setActiveCareerState({
          careerIndex: 0,
          groupIndex: 0,
        });
    }
  }, [activeCareerState.careerIndex, activeCareerState.groupIndex, isMobile]);

  const allCareers = useMemo(
    () =>
      careersFromOtherIndustries.length
        ? [
            { title: "Recommends", careers: careersFromSelectedIndustries },
            {
              title: "Other industry opportunities",
              careers: careersFromOtherIndustries,
            },
          ]
        : [{ title: "Recommends", careers: careersFromSelectedIndustries }],
    [careersFromOtherIndustries, careersFromSelectedIndustries],
  );

  const changeActiveCareerHandler = (
    groupIndex: number,
    careerIndex: number,
  ) => {
    if (isMobile) {
      modalStore.openModal(
        <CareerInfoModal
          career={allCareers[groupIndex].careers[careerIndex]}
        />,
        { modalClassName: scss.modal },
      );
    } else setActiveCareerState({ careerIndex, groupIndex });
  };

  const career =
    allCareers[activeCareerState.groupIndex]?.careers[
      activeCareerState.careerIndex
    ];

  return (
    <div className={scss.container}>
      <div className={scss.groupsSection}>
        <div className={scss.groupsSectionInner}>
          {allCareers.map((group, i) => (
            <CareersGroup
              key={i}
              groupIndex={i}
              title={group.title}
              careers={getCareersShortInfo(group.careers)}
              changeActiveCareerHandler={changeActiveCareerHandler}
              activeCareerState={activeCareerState}
            />
          ))}
        </div>
      </div>
      {career && (
        <CareerInfo
          className={scss.careerInfoWrapper}
          key={career.id}
          career={career}
        />
      )}
    </div>
  );
};

export default observer(CareerMatchMainSection);
