import React from "react";
import { AssessmentStepOptionDto } from "../../../api/data-contracts";
import cn from "classnames";
import { KeyCode } from "../../../utils/keyCode";
import scss from "./styles.module.scss";

type Props = {
  option: AssessmentStepOptionDto;
  isChecked: boolean;
  onChange: (optionId: string, value: boolean) => void;
};

const Toggle = ({ option, isChecked, onChange }: Props) => {
  const onChangeHandler = () => {
    onChange(option.relationId, true);
  };

  const onKeyUpHandler: React.KeyboardEventHandler<HTMLLabelElement> = (e) => {
    if (e.keyCode === KeyCode.ENTER || e.keyCode === KeyCode.SPACE)
      onChangeHandler();
  };

  return (
    <label
      className={cn(scss.container, { [scss.checked]: isChecked })}
      onKeyUp={onKeyUpHandler}
      tabIndex={1}
    >
      <input
        className={scss.originCheckbox}
        type="checkbox"
        checked={isChecked}
        onChange={onChangeHandler}
        tabIndex={0}
      />
      <div className={scss.toggle} />
      <span
        className={cn(scss.text, scss.left, { [scss.textChecked]: !isChecked })}
      >
        no
      </span>
      <span
        className={cn(scss.text, scss.right, { [scss.textChecked]: isChecked })}
      >
        yes
      </span>
    </label>
  );
};

export default Toggle;
