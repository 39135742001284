import React, { useState } from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import { validatePassword } from "../../../../utils/validatePassword";
import { useAppState } from "../../../../AppState";
import PasswordIcon from "../../../../icons/PasswordIcon";
import { AccountSteps } from "../../AccountModal";
import { ErrorType } from "../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
};

type StateProps = {
  currentPassword: string;
  currentPasswordError: string;
  password: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
};

const ChangePasswordForm = ({ changeStepHandler }: Props) => {
  const {
    service: { authService },
  } = useAppState();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<StateProps>({
    currentPassword: "",
    currentPasswordError: "",
    password: "",
    passwordError: "",
    confirmPassword: "",
    confirmPasswordError: "",
  });

  const changeStateHandler = (value: string, target: HTMLInputElement) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: value,
      ...(target.name.startsWith("confirm")
        ? { confirmNewPasswordError: "" }
        : target.name.startsWith("current")
        ? { currentPasswordError: "" }
        : { passwordError: "" }),
    }));
  };

  const submit = async () => {
    if (submitting) return;

    const formattedCurrentPassword = state.currentPassword.trim();
    const validCurrentPassword = validatePassword(formattedCurrentPassword);

    if (!validCurrentPassword)
      setState((prevState) => ({
        ...prevState,
        currentPasswordError: "Invalid password",
      }));

    const formattedPassword = state.password.trim();
    const validPassword = validatePassword(formattedPassword);

    if (!validPassword)
      setState((prevState) => ({
        ...prevState,
        passwordError: "Invalid password",
      }));

    const formattedConfirmPassword = state.confirmPassword.trim();
    const validConfirmPassword = validatePassword(formattedConfirmPassword);

    if (!validConfirmPassword)
      setState((prevState) => ({
        ...prevState,
        confirmPasswordError: "Invalid password",
      }));

    if (formattedPassword !== formattedConfirmPassword) {
      setState((prevState) => ({
        ...prevState,
        passwordError: "Password and Confirm password do not match",
        confirmPasswordError: "Password and Confirm password do not match",
      }));
      return;
    }

    if (!validConfirmPassword)
      setState((prevState) => ({
        ...prevState,
        confirmPasswordError: "Invalid password",
      }));

    if (validConfirmPassword && validPassword && validCurrentPassword) {
      try {
        setSubmitting(true);
        await authService.changePassword({
          currentPassword: formattedCurrentPassword,
          password: formattedPassword,
          passwordConfirmation: formattedConfirmPassword,
        });
        changeStepHandler("SUCCESS")();
      } catch (e: any) {
        if (e?.response?.data?.error?.name === ErrorType.VALIDATION_ERROR) {
          setState((prevState) => ({
            ...prevState,
            currentPasswordError: e.response.data.error.message,
          }));
        }
      }

      setSubmitting(false);
    }
  };

  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title="change password"
    >
      <Input
        value={state.currentPassword}
        onChange={changeStateHandler}
        placeholder="Actual password"
        errorText={state.currentPasswordError}
        type="password"
        icon={<PasswordIcon />}
        name="currentPassword"
      />
      <Input
        value={state.password}
        onChange={changeStateHandler}
        placeholder="New password"
        errorText={state.passwordError}
        type="password"
        icon={<PasswordIcon />}
        name="password"
      />
      <Input
        value={state.confirmPassword}
        onChange={changeStateHandler}
        placeholder="Repeat password"
        errorText={state.confirmPasswordError}
        type="password"
        icon={<PasswordIcon />}
        name="confirmPassword"
      />
      <Button
        className={scss.changePassword}
        variant="outfilled"
        onClick={changeStepHandler("FORGOT_PASSWORD")}
      >
        Forgot password?
      </Button>
      <Button
        className={scss.button}
        uppercase
        variant="filled-primary"
        onClick={submit}
      >
        Change password
      </Button>
    </AccountModalInnerLayout>
  );
};

export default ChangePasswordForm;
