import { AssessmentStepDto } from "../api/data-contracts";
import { useMemo } from "react";

export const useGetProgressSteps = (steps: AssessmentStepDto[]) => {
  return useMemo(() => {
    return steps?.reduce(
      (
        acc: Record<
          string,
          {
            completedCount: number;
            maxCount: number;
            stepsIds: number[];
          }
        >,
        item,
      ) => {
        const categoryKey =
          item.category === "PERSONALITY"
            ? "personality"
            : item.category === "VALUES"
            ? "values"
            : item.category === "SUBJECTS"
            ? "subjects"
            : "interests";

        acc[categoryKey].maxCount++;
        if (item.completed) acc[categoryKey].completedCount++;
        acc[categoryKey].stepsIds.push(item.id);

        return acc;
      },
      {
        interests: {
          completedCount: 0,
          maxCount: 0,
          stepsIds: [],
        },
        personality: {
          completedCount: 0,
          maxCount: 0,
          stepsIds: [],
        },
        values: {
          completedCount: 0,
          maxCount: 0,
          stepsIds: [],
        },
        subjects: {
          completedCount: 0,
          maxCount: 0,
          stepsIds: [],
        },
      },
    );
  }, [steps]);
};
