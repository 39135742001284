import React from "react";
import { PaymentDto } from "../../../../../../api/data-contracts";
import { getDateWithDay } from "../../../../../../utils/dateUtils";
import PaymentIconPayPal from "../../../../../../icons/PaymentIconPayPal";
import PaymentIconVisa from "../../../../../../icons/PaymentIconVisa";
import PaymentIconMasterCard from "../../../../../../icons/PaymentIconMasterCard";
import PaymentIconAmericanExpress from "../../../../../../icons/PaymentIconAmericanExpress";
import scss from "./styles.module.scss";

type Props = {
  payment: PaymentDto;
};

const PaymentCard = ({ payment }: Props) => {
  return (
    <a
      className={scss.container}
      href={payment?.receiptURL}
      target="_blank"
      rel="noreferrer"
    >
      <p className={scss.title}>{payment.title}</p>
      <div className={scss.dateWrapper}>{getDateWithDay(payment.date)}</div>
      <div className={scss.paymentInfoWrapper}>
        <div className={scss.summary}>{`-$${payment.summary}`}</div>
        {payment.paymentMethod === "CARD" ? (
          <>
            <PaymentIconVisa />
            <PaymentIconMasterCard />
            <PaymentIconAmericanExpress />
          </>
        ) : (
          <PaymentIconPayPal />
        )}
      </div>
    </a>
  );
};

export default PaymentCard;
