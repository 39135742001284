import React from "react";
import scss from "./styles.module.scss";

export type BenefitProps = {
  title: string;
  description: string;
  imageSrc: string;
};

const BenefitItem = ({ description, imageSrc, title }: BenefitProps) => {
  return (
    <div className={scss.container}>
      <img className={scss.image} src={imageSrc} alt={title} />
      <p className={scss.title}>{title}</p>
      <p className={scss.description}>{description}</p>
    </div>
  );
};

export default BenefitItem;
