import { CareerInfoDto } from "../api/data-contracts";
import { getPercentValue } from "./numberUtils";

export type CareerShortInfoDto = {
  title: string;
  industryTitle: string;
  percentage: number;
};

export const getCareersShortInfo = (
  careers: CareerInfoDto[],
): CareerShortInfoDto[] => {
  return careers.map((career) => ({
    title: career.title,
    industryTitle: career.industryTitle,
    percentage: getPercentValue(
      career.careerPoints.totalPoints,
      career.careerPoints.maxPoints,
    ),
  }));
};
