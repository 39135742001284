import { useRef } from "react";
import { useAppState } from "../AppState";

const usePreloader = () => {
  const {
    store: { preloaderStore },
  } = useAppState();
  return useRef((show: boolean, type: "GLOBAL" | "PAGE" = "PAGE") => {
    if (show !== preloaderStore._show)
      preloaderStore.showPreloader(show, show ? type : null);
  }).current;
};

export default usePreloader;
