import React from "react";
import Toggle from "../../../../../../../../components/shared/Toggle/Toggle";
import { AssessmentStepOptionDto } from "../../../../../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  option: AssessmentStepOptionDto;
  onChange: (optionId: string, value: boolean) => void;
  isChecked: boolean;
};

const JobGroupToggle = ({ option, onChange, isChecked }: Props) => {
  return (
    <div className={scss.container}>
      <p className={scss.title}>{option.title}</p>
      <Toggle option={option} isChecked={isChecked} onChange={onChange} />
    </div>
  );
};

export default JobGroupToggle;
