import { memo, SVGProps } from "react";

const PaymentIconVisa = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="18"
      viewBox="0 0 29 18"
      fill="none"
      {...props}
    >
      <path
        d="M10.7578 12.773L12.0477 5.29166H14.1109L12.8201 12.773H10.7578Z"
        fill="#0E4595"
      />
      <path
        d="M20.273 5.45301C19.8642 5.30144 19.2237 5.13879 18.4238 5.13879C16.3851 5.13879 14.9491 6.15348 14.9369 7.60774C14.9254 8.68273 15.9621 9.28242 16.7447 9.64028C17.5478 10.007 17.8178 10.2409 17.8139 10.5683C17.8088 11.0698 17.1726 11.2988 16.5795 11.2988C15.7538 11.2988 15.3151 11.1855 14.6375 10.9062L14.3716 10.7873L14.082 12.462C14.5639 12.6709 15.455 12.8518 16.3803 12.8612C18.5491 12.8612 19.957 11.8581 19.9731 10.3051C19.9807 9.45405 19.4311 8.80644 18.2407 8.27248C17.5196 7.92639 17.078 7.69544 17.0826 7.345C17.0826 7.03403 17.4565 6.70151 18.2643 6.70151C18.9389 6.69115 19.4277 6.83656 19.8084 6.98813L19.9933 7.07446L20.273 5.45301Z"
        fill="#0E4595"
      />
      <path
        d="M25.5825 5.29163H23.9882C23.4943 5.29163 23.1247 5.42485 22.9078 5.91203L19.8438 12.7682H22.0103C22.0103 12.7682 22.3645 11.8463 22.4446 11.6439C22.6813 11.6439 24.786 11.6471 25.0869 11.6471C25.1486 11.9091 25.3379 12.7682 25.3379 12.7682H27.2524L25.5825 5.29163ZM23.053 10.1225C23.2237 9.69146 23.8751 8.03114 23.8751 8.03114C23.8629 8.05105 24.0445 7.598 24.1486 7.3171L24.2881 7.96212C24.2881 7.96212 24.6831 9.74794 24.7657 10.1224H23.053V10.1225Z"
        fill="#0E4595"
      />
      <path
        d="M1.7744 5.29166L1.74805 5.44728C2.56364 5.64242 3.29208 5.92485 3.92984 6.27618L5.76054 12.7611L7.94347 12.7587L11.1916 5.29166H9.00598L6.9861 10.3934L6.77094 9.35657C6.76086 9.32454 6.7499 9.29245 6.73869 9.26028L6.0365 5.92327C5.91164 5.44954 5.54943 5.30817 5.10132 5.29166H1.7744Z"
        fill="#0E4595"
      />
    </svg>
  );
};

export default memo(PaymentIconVisa);
