import React, { ReactNode, useEffect, useRef } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  title?: string;
  subtitle?: ReactNode;
  subtitleMaxWidth?: number;
};

const AccountModalInnerLayout = ({
  children,
  className,
  title,
  subtitle,
  innerClassName,
  subtitleMaxWidth,
}: Props) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        scss.container,
        {
          [scss.show]: mount.current,
        },
        className,
      )}
    >
      {title || subtitle ? (
        <div className={scss.titleWrapper}>
          {title ? <h2 className={scss.title}>{title}</h2> : null}
          {subtitle ? (
            <p
              style={{ maxWidth: subtitleMaxWidth }}
              className={scss.subtitle}
              dangerouslySetInnerHTML={{ __html: subtitle as string }}
            />
          ) : null}
        </div>
      ) : null}
      <div className={cn(scss.containerInner, innerClassName)}>{children}</div>
    </div>
  );
};

export default AccountModalInnerLayout;
