import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

const useQueryParams = () => {
  const { pathname, search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return { pathname, query };
};

export const useClearQuery = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(window.location.pathname, { replace: true });
  }, [navigate]);
};

export const useGetQueryParam = () => {
  const { query } = useQueryParams();

  return {
    resetPasswordToken: query.get("resetPasswordToken"),
    paymentSuccess: query.get("payment-success"),
    paypalPaymentSuccess: query.get("paypal-payment-success"),
    payPalOrderId: query.get("token"),
  };
};
