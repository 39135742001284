import React from "react";
import QuickStartModalInnerLayout from "../QuickStartModalInnerLayout/QuickStartModalInnerLayout";
import Button from "../../../shared/Button/Button";
import { CareerMatchDto } from "../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  activeCareerMatch: CareerMatchDto;
};

const ContinueAssessment = ({ activeCareerMatch }: Props) => {
  return (
    <QuickStartModalInnerLayout
      title="Continue Assessment"
      subtitle="Continue your unfinished assessment"
      innerClassName={scss.container}
    >
      <p className={scss.text}>
        You have unfinished assessment. You can pick up where you left off right
        now!
      </p>
      <Button
        className={scss.button}
        variant="filled-primary"
        uppercase
        type="innerLink"
        to={`/assessment/${activeCareerMatch.userAssessmentId}`}
      >
        Continue
      </Button>
    </QuickStartModalInnerLayout>
  );
};

export default ContinueAssessment;
