import { memo, SVGProps } from "react";

const PaymentIconMasterCard = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1331_432)">
        <path
          d="M11.4277 1.22659H6.55664V10.2414H11.4277V1.22659Z"
          fill="#FF5A00"
        />
        <path
          d="M6.88151 5.73402C6.88151 3.90247 7.71737 2.27691 9 1.2266C8.05604 0.462062 6.86529 0 5.56645 0C2.48959 0 0 2.56454 0 5.73402C0 8.9035 2.48959 11.468 5.56645 11.468C6.86529 11.468 8.05604 11.006 9 10.2414C7.71557 9.20598 6.88151 7.56557 6.88151 5.73402Z"
          fill="#EB001B"
        />
        <path
          d="M18 5.73402C18 8.9035 15.5104 11.468 12.4335 11.468C11.1347 11.468 9.94396 11.006 9 10.2414C10.2988 9.18928 11.1185 7.56557 11.1185 5.73402C11.1185 3.90247 10.2826 2.27691 9 1.2266C9.94215 0.462062 11.1329 0 12.4317 0C15.5104 0 18 2.58124 18 5.73402Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_432">
          <rect width="18" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(PaymentIconMasterCard);
