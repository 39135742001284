import React, { useCallback } from "react";
import { AssessmentStepDto } from "../../../../../../api/data-contracts";
import QuestionSectionLayout from "../QuestionSectionLayout/QuestionSectionLayout";
import Checkbox from "../../../../../../components/shared/Checkbox/Checkbox";
import { useAppState } from "../../../../../../AppState";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

type Props = {
  step: AssessmentStepDto;
  currentStepIndex: number;
};

const SelectIndustriesQuestion = ({ step, currentStepIndex }: Props) => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const onChangeHandler = useCallback(
    (optionId: string, value: boolean) => {
      if (step.selectedOptions && step.selectedOptions[optionId]) {
        const { [optionId]: optionForRemove, ...rest } = step.selectedOptions;
        assessmentStore.setStepSelectionOptions(currentStepIndex, rest);
      } else
        assessmentStore.setStepSelectionOptions(currentStepIndex, {
          ...step.selectedOptions,
          [optionId]: { selected: value },
        });
    },
    [currentStepIndex, assessmentStore, step.selectedOptions],
  );

  return (
    <QuestionSectionLayout
      title={step.questionTitle}
      withSeparator
      innerClassName={scss.container}
    >
      {step.options.map((option) => (
        <Checkbox
          key={option.relationId}
          option={option}
          onChange={onChangeHandler}
          isChecked={!!step.selectedOptions?.[option.relationId]}
        />
      ))}
    </QuestionSectionLayout>
  );
};

export default observer(SelectIndustriesQuestion);
