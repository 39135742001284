import { memo, SVGProps } from "react";

const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M15.564 16.3392L10.8015 11.5759C8.68288 13.0821 5.76352 12.7137 4.08555 10.7283C2.40758 8.74296 2.53079 5.80303 4.36899 3.96504C6.2067 2.12624 9.14693 2.0025 11.1326 3.68038C13.1184 5.35827 13.487 8.27791 11.9807 10.3967L16.7432 15.16L15.5648 16.3384L15.564 16.3392ZM7.90399 4.16669C6.32374 4.16634 4.9604 5.2756 4.63938 6.8229C4.31837 8.3702 5.12791 9.93026 6.57789 10.5586C8.02786 11.1869 9.71974 10.7107 10.6292 9.41841C11.5386 8.1261 11.4157 6.3728 10.3348 5.22004L10.839 5.72004L10.2707 5.15337L10.2607 5.14337C9.63714 4.51602 8.78848 4.16431 7.90399 4.16669Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(SearchIcon);
