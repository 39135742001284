import React, { useEffect } from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import EmailIcon from "../../../../icons/EmailIcon";
import { useAppState } from "../../../../AppState";
import { observer } from "mobx-react-lite";
import Button from "../../../shared/Button/Button";
import LogoutIcon from "../../../../icons/LogoutIcon";
import { AccountSteps } from "../../AccountModal";
import PaymentsHistory from "./components/PaymentsHistory/PaymentsHistory";
import CompletedSmallIcon from "../../../../icons/CompletedSmallIcon";
import WarningIcon from "../../../../icons/WarningIcon";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
};

const Personal = ({ changeStepHandler }: Props) => {
  const {
    service: { userService },
    store: { userStore },
  } = useAppState();

  useEffect(() => {
    userService.fetchMe();
  }, [userService]);

  const user = userStore.user;

  return (
    <AccountModalInnerLayout title="User Profile" innerClassName={scss.wrapper}>
      <div className={scss.container}>
        <div className={scss.email}>
          <EmailIcon className={scss.emailIcon} /> {user.email}
          <div
            className={cn(scss.iconWrapper, {
              [scss.warning]: !user.emailConfirmed,
            })}
          >
            {user.emailConfirmed ? (
              <CompletedSmallIcon height={14} width={14} />
            ) : (
              <WarningIcon />
            )}
          </div>
        </div>
        <div className={scss.actionsWrapper}>
          <Button
            className={scss.changePasswordButton}
            variant="outfilled"
            onClick={changeStepHandler("CHANGE_PASSWORD")}
          >
            Change password
          </Button>
          <button
            className={scss.logoutButton}
            onClick={changeStepHandler("LOG_OUT")}
          >
            <LogoutIcon />
            Log Out
          </button>
        </div>
        <div className={scss.separator} />
        <PaymentsHistory payments={user.payments} />
      </div>
    </AccountModalInnerLayout>
  );
};

export default observer(Personal);
