import { action, computed, makeObservable, observable } from "mobx";

export type PreloaderType = "GLOBAL" | "PAGE";

export class PreloaderStore {
  _show: boolean = true;
  _type: PreloaderType = "GLOBAL";

  constructor() {
    makeObservable(this, {
      _show: observable,
      _type: observable,
      show: computed,
      type: computed,
      showPreloader: action,
    });
  }

  showPreloader = (show: boolean, type: PreloaderType | null) => {
    this._show = show;
    if (type) this._type = type;
  };

  get type() {
    return this._type;
  }

  get show() {
    return this._show;
  }
}
