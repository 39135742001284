import React from "react";
import { BrowserRouter } from "react-router-dom";
import ResolutionDetector from "./components/ResolutionDetector/ResolutionDetector";
import Router from "./routes/Router";
import { queryClient } from "./api/apiClient";
import { QueryClientProvider } from "react-query";
import Modal from "./components/Modal/Modal";
import { AppStateProvider } from "./AppState";
import GlobalPreloader from "./components/GlobalPreloader/GlobalPreloader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";

function App() {
  return (
    <>
      <AppStateProvider>
        <ResolutionDetector />
        <GlobalPreloader />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Router />
            <Modal />
            <ToastContainer />
          </BrowserRouter>
        </QueryClientProvider>
      </AppStateProvider>
    </>
  );
}

export default App;
