import { makeAutoObservable } from "mobx";
import { PriceDto } from "../api/data-contracts";

export class PageStore {
  private _price: PriceDto = {} as PriceDto;

  constructor() {
    makeAutoObservable(this);
  }

  setPrice = (price: PriceDto) => {
    this._price = price;
  };

  get price() {
    return this._price;
  }
}
