import { AppState } from "../AppState";
import {
  checkPaypalOrderStatus,
  checkPromoCode,
  createOrGetActiveCareerMatch,
  createPayment,
  getStripePaymentMode,
  updateAssessmentById,
} from "../api/api";
import {
  AssessmentStepSelectedOptionsDto,
  CheckPromoCodeReqDto,
  CreatePaymentReqDto,
} from "../api/data-contracts";
import { checkEqualStepState } from "../utils/checkEqualStepState";
import { runInAction } from "mobx";
import { toast } from "react-toastify";

export class AssessmentService {
  private root: AppState;

  constructor(root: AppState) {
    this.root = root;
  }

  public createOrGetActiveCareerMatch = async () => {
    const { data } = await createOrGetActiveCareerMatch();
    return data;
  };

  public createPayment = async (body: CreatePaymentReqDto) => {
    try {
      const { data } = await createPayment(body);
      return data;
    } catch (e) {
      toast("Payment failed. Please try again later.", {
        type: "error",
        position: "bottom-right",
      });
      this.root.store.modalStore.closeModal();
    }
  };

  public checkPaypalOrderStatus = async (body: { orderId: string }) => {
    try {
      const { data } = await checkPaypalOrderStatus(body);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  public updateAssessmentById = async (
    assessmentId: string,
    currentStepIndex: number,
  ) => {
    const currentStep = this.root.store.assessmentStore.steps[currentStepIndex];
    const currentState = currentStep.selectedOptions;
    const initialState =
      this.root.store.assessmentStore.initialSteps[currentStepIndex]
        .selectedOptions;

    const isEqualState = checkEqualStepState(initialState, currentState);

    if (
      !isEqualState ||
      (currentStep.category === "INDUSTRY_SELECT_RANK" &&
        !currentStep.completed)
    ) {
      try {
        const { data } = await updateAssessmentById({
          id: assessmentId,
          category: currentStep.category,
          stepId: currentStep.id,
          selectedOptions: currentState as AssessmentStepSelectedOptionsDto,
        });
        if (data) {
          this.root.store.assessmentStore.setAssessment(data);
          if (data.status === "FINISHED") {
            runInAction(() => {
              this.root.store.userStore.clearActiveAssessmentId();
              this.root.store.userStore.incrementCareerMatchesCount();
            });
          }

          return data;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  public getStripePaymentMode = async () => {
    try {
      const { data } = await getStripePaymentMode();
      return data.stripeLiveMode;
    } catch (e) {
      console.log(e);
    }
  };

  public checkPromoCode = async ({ code }: CheckPromoCodeReqDto) => {
    try {
      const { data } = await checkPromoCode({ code });
      return { discount: data.discount, code: data.code, errorMessage: null };
    } catch (e: any) {
      return {
        discount: null,
        code: null,
        errorMessage: e.response.data.error.message,
      };
    }
  };
}
