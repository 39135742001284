import { memo, SVGProps } from "react";

const ArrowMini = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      {...props}
    >
      <path d="M4 6L7.4641 0H0.535898L4 6Z" fill="currentColor" />
    </svg>
  );
};

export default memo(ArrowMini);
