import React, { useCallback } from "react";
import { AssessmentStepDto } from "../../../../../../api/data-contracts";
import QuestionSectionLayout from "../QuestionSectionLayout/QuestionSectionLayout";
import { useAppState } from "../../../../../../AppState";
import { observer } from "mobx-react-lite";
import JobGroupToggle from "./components/JobGroupToggle/JobGroupToggle";
import scss from "./styles.module.scss";

type Props = {
  step: AssessmentStepDto;
  currentStepIndex: number;
};

const SelectJobGroupsQuestion = ({ currentStepIndex, step }: Props) => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const onChangeHandler = useCallback(
    (optionId: string, value: boolean) => {
      if (step.selectedOptions && step.selectedOptions[optionId]) {
        const { [optionId]: optionForRemove, ...rest } = step.selectedOptions;
        assessmentStore.setStepSelectionOptions(currentStepIndex, rest);
      } else
        assessmentStore.setStepSelectionOptions(currentStepIndex, {
          ...step.selectedOptions,
          [optionId]: { selected: value },
        });
    },
    [currentStepIndex, assessmentStore, step.selectedOptions],
  );

  return (
    <QuestionSectionLayout
      title={step.questionTitle}
      caption="Interest"
      innerClassName={scss.container}
      withSeparator
    >
      {step.options.map((option, i) => (
        <div className={scss.optionWrapper} key={option.relationId}>
          {i ? <div className={scss.separator} /> : null}
          <JobGroupToggle
            option={option}
            onChange={onChangeHandler}
            isChecked={!!step.selectedOptions?.[option.relationId]}
          />
        </div>
      ))}
    </QuestionSectionLayout>
  );
};

export default observer(SelectJobGroupsQuestion);
