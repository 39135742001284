import React from "react";
import scss from "./styles.module.scss";

export type CareerFeatureDto = {
  title: string;
  description: string;
};

const CareerFeatureItem = (feature: CareerFeatureDto) => {
  return (
    <div className={scss.featureContainer}>
      <p className={scss.featureTitle}>{feature.title}</p>
      <p className={scss.featureDescription}>{feature.description}</p>
    </div>
  );
};

export default CareerFeatureItem;
