import { memo, SVGProps } from "react";

const Preloader = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="64"
      version="1.1"
      id="L3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
      {...props}
    >
      <circle
        fill="none"
        stroke="#FF872D"
        strokeWidth="6"
        cx="50"
        cy="50"
        r="44"
        style={{ opacity: 0.5 }}
      />
      <circle fill="#fff" stroke="#FD6E03" strokeWidth="4" cx="8" cy="54" r="6">
        <animateTransform
          attributeName="transform"
          dur="2s"
          type="rotate"
          from="0 50 48"
          to="360 50 52"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default memo(Preloader);
