import { makeAutoObservable } from "mobx";
import {
  AssessmentDto,
  AssessmentStepDto,
  AssessmentStepSelectedOptionsDto,
  CareerMatchDto,
} from "../api/data-contracts";

export class AssessmentStore {
  private _activeCareerMatch: CareerMatchDto = {} as CareerMatchDto;
  private _assessment: AssessmentDto = {} as AssessmentDto;
  private _initialSteps: AssessmentStepDto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setActiveCareerMatch = (careerMatch: CareerMatchDto) => {
    this._activeCareerMatch = careerMatch;
  };

  get activeCareerMatch() {
    return this._activeCareerMatch;
  }

  setAssessment = (assessment: AssessmentDto) => {
    this._assessment = assessment;
    this._initialSteps = assessment.steps;
  };

  get assessment() {
    return this._assessment;
  }

  get steps() {
    return this._assessment.steps;
  }

  get initialSteps() {
    return this._initialSteps;
  }

  setStepSelectionOptions = (
    stepIndex: number,
    options: AssessmentStepSelectedOptionsDto,
  ) => {
    this._assessment.steps[stepIndex].selectedOptions = options;
  };
}
