import { AuthService } from "./AuthService";
import { AppState } from "../AppState";
import { UserService } from "./UserService";
import { AssessmentService } from "./AssessmentService";

export class GlobalService {
  authService: AuthService;
  userService: UserService;
  assessmentService: AssessmentService;

  constructor(root: AppState) {
    this.authService = new AuthService(root);
    this.userService = new UserService(root);
    this.assessmentService = new AssessmentService(root);
  }
}
