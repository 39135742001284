import React, { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import ArrowMini from "../../../icons/ArrowMini";
import SearchIcon from "../../../icons/SearchIcon";
import scss from "./styles.module.scss";

type Props = {
  options: string[];
  activeOption: string;
  setActiveOption: (option: string) => void;
};

const Dropdown = ({ options, activeOption, setActiveOption }: Props) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    if (searchValue)
      return options.filter((el) =>
        el.toLowerCase().includes(searchValue.toLowerCase()),
      );
    else return options;
  }, [options, searchValue]);

  useEffect(() => {
    if (menuOpened) {
      const timeout = setTimeout(() => {
        menuRef.current?.classList.add(scss.menuOpened);
        setTimeout(() => inputRef.current?.focus(), 100);
      }, 25);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [menuOpened]);

  useEffect(() => {
    if (menuOpened) {
      const handler = (e: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node))
          setMenuOpened(false);
      };

      setTimeout(() => document.addEventListener("click", handler), 20);

      return () => {
        document.removeEventListener("click", handler);
      };
    }
  }, [menuOpened]);

  const openMenuHandler = () => {
    setMenuOpened((prevState) => !prevState);
  };

  const changeOptionHandler = (option: string) => () => {
    setActiveOption(option);
    setMenuOpened(false);
  };

  const searchOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={scss.selectMenuWrapper}>
      <button
        className={cn(scss.openButton, {
          [scss.openButtonOpened]: menuOpened,
        })}
        onClick={openMenuHandler}
      >
        {activeOption} <ArrowMini />
      </button>
      {menuOpened ? (
        <div ref={menuRef} className={cn(scss.dropdownMenu)}>
          <div className={scss.searchInputWrapper}>
            <SearchIcon />
            <input
              className={scss.searchInput}
              ref={inputRef}
              type="text"
              value={searchValue}
              placeholder="Search..."
              onChange={searchOnChangeHandler}
            />
          </div>
          {filteredOptions.map((el) => (
            <button
              className={cn(scss.optionButton, {
                [scss.optionButtonActive]: el === activeOption,
              })}
              onClick={changeOptionHandler(el)}
              key={el}
              value={el}
            >
              {el}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
