export const checkEqualStepState = (
  obj1?: Record<string, any>,
  obj2?: Record<string, any>,
) => {
  if (!obj1 || !obj2) return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (!keys2.includes(key)) return false;

    if (val1 !== val2 && typeof val1 === "object" && typeof val2 === "object") {
      if (!checkEqualStepState(val1, val2)) {
        return false;
      }
    } else if (
      typeof val1 === "number" &&
      typeof val2 === "number" &&
      val1 !== val2
    )
      return false;
  }

  return true;
};
