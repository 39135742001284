import React, { useState } from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import Input from "../../../shared/Input/Input";
import EmailIcon from "../../../../icons/EmailIcon";
import Button from "../../../shared/Button/Button";
import isEmail from "validator/lib/isEmail";
import { useAppState } from "../../../../AppState";
import { AccountSteps } from "../../AccountModal";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
};

type StateProps = {
  email: string;
  emailError: string;
};

const ForgotPasswordForm = ({ changeStepHandler }: Props) => {
  const {
    service: { authService },
  } = useAppState();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<StateProps>({
    email: "",
    emailError: "",
  });

  const changeStateHandler = (value: string) => {
    setState({ email: value, emailError: "" });
  };

  const submit = async () => {
    if (submitting) return;

    const formattedEmail = state.email.toLowerCase().trim();
    const validEmail = isEmail(formattedEmail);

    if (!validEmail) {
      setState((prevState) => ({ ...prevState, emailError: "Invalid email" }));
      return;
    }

    setSubmitting(true);

    try {
      await authService.forgotPassword({
        email: formattedEmail,
      });
      changeStepHandler("FORGOT_PASSWORD_MESSAGE")();
    } catch (_) {}

    setSubmitting(false);
  };

  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title="Forgot password"
    >
      <Input
        value={state.email}
        onChange={changeStateHandler}
        placeholder="Enter your email..."
        errorText={state.emailError}
        icon={<EmailIcon />}
        name="email"
      />
      <Button
        className={scss.button}
        loading={submitting}
        uppercase
        variant="filled-primary"
        onClick={submit}
      >
        Reset password
      </Button>
    </AccountModalInnerLayout>
  );
};

export default ForgotPasswordForm;
