import React, { ReactNode } from "react";
import Button from "../../../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

export type GuideCardProps = {
  title: ReactNode;
  description: string;
  button?: { title: string; onClick?: () => void };
  price?: {
    oldPrice: string;
    currentPrice: string;
  };
};

type Props = {
  card: GuideCardProps;
  stepNumber: number;
};

const GuideCardItem = ({ stepNumber, card }: Props) => {
  return (
    <div
      className={scss.container}
      style={{ backgroundImage: `url(/assets/guide-card-bg.png)` }}
    >
      <p className={scss.stepNumber}>{`step ${String(stepNumber).padStart(
        2,
        "0",
      )}`}</p>
      <p
        className={scss.title}
        dangerouslySetInnerHTML={{ __html: card.title as string }}
      />
      <p className={scss.description}>{card.description}</p>
      {card.button && (
        <Button
          className={scss.linkButton}
          type="button"
          onClick={card.button.onClick}
          variant="filled-primary"
          uppercase
        >
          {card.button.title}
        </Button>
      )}
      {card.price && (
        <div className={scss.priceWrapper}>
          <p className={scss.oldPrice}>{card.price.oldPrice}</p>
          <p className={scss.newPrice}>{card.price.currentPrice}</p>
        </div>
      )}
    </div>
  );
};

export default GuideCardItem;
