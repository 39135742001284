import React, { useState } from "react";
import CloseIcon from "../../icons/CloseIcon";
import cn from "classnames";
import { useAppState } from "../../AppState";
import ForgotPasswordForm from "./comonents/ForgotPasswordForm/ForgotPasswordForm";
import LoginRegisterForm from "./comonents/LoginRegisterForm/LoginRegisterForm";
import ResetPasswordForm from "./comonents/ResetPasswordForm/ResetPasswordForm";
import Personal from "./comonents/Personal/Personal";
import ChangePasswordForm from "./comonents/ChangePasswordForm/ChangePasswordForm";
import LogOut from "./comonents/LogOut/LogOut";
import ArrowLong from "../../icons/ArrowLong";
import SuccessMessage from "./comonents/SuccessMessage/SuccessMessage";
import ForgotPasswordMessage from "./comonents/ForgotPasswordMessage/ForgotPasswordMessage";
import InvalidTokenMessage from "./comonents/InvalidTokenMessage/InvalidTokenMessage";
import scss from "./styles.module.scss";

export type AccountSteps =
  | "LOGIN"
  | "REGISTRATION"
  | "FORGOT_PASSWORD"
  | "FORGOT_PASSWORD_MESSAGE"
  | "RESET_PASSWORD"
  | "CHANGE_PASSWORD"
  | "INVALID_TOKEN_MESSAGE"
  | "PERSONAL"
  | "LOG_OUT"
  | "SUCCESS";

export type AccountStepState = {
  prevStep: AccountSteps;
  currentStep: AccountSteps;
};

type Props = {
  startStep: AccountSteps;
  code?: string;
};

const AccountModal = ({ startStep, code }: Props) => {
  const {
    store: { modalStore },
  } = useAppState();
  const [step, setStep] = useState<AccountStepState>({
    prevStep: startStep,
    currentStep: startStep,
  });

  const goBackHandler = () => {
    setStep((prevState) => {
      return prevState.prevStep === "FORGOT_PASSWORD"
        ? {
            currentStep: "PERSONAL",
            prevStep: "FORGOT_PASSWORD",
          }
        : { currentStep: prevState.prevStep, prevStep: prevState.currentStep };
    });
  };
  const changeStepHandler = (step: AccountSteps) => () => {
    setStep((prevState) => ({
      prevStep: prevState.currentStep,
      currentStep: step,
    }));
  };

  const showGoBackButton =
    ["FORGOT_PASSWORD", "LOG_OUT", "CHANGE_PASSWORD"].includes(
      step.currentStep,
    ) && step.prevStep !== "INVALID_TOKEN_MESSAGE";

  return (
    <div className={cn(scss.container, scss[step.currentStep])}>
      <CloseIcon className={scss.closeIcon} onClick={modalStore.closeModal} />
      {showGoBackButton ? (
        <ArrowLong className={scss.goBackIcon} onClick={goBackHandler} />
      ) : null}
      {step.currentStep === "FORGOT_PASSWORD" ? (
        <ForgotPasswordForm changeStepHandler={changeStepHandler} />
      ) : step.currentStep === "FORGOT_PASSWORD_MESSAGE" ? (
        <ForgotPasswordMessage />
      ) : step.currentStep === "RESET_PASSWORD" ? (
        <ResetPasswordForm
          code={code as string}
          changeStepHandler={changeStepHandler}
        />
      ) : step.currentStep === "CHANGE_PASSWORD" ? (
        <ChangePasswordForm changeStepHandler={changeStepHandler} />
      ) : step.currentStep === "INVALID_TOKEN_MESSAGE" ? (
        <InvalidTokenMessage changeStepHandler={changeStepHandler} />
      ) : step.currentStep === "PERSONAL" ? (
        <Personal changeStepHandler={changeStepHandler} />
      ) : step.currentStep === "LOG_OUT" ? (
        <LogOut changeStepHandler={changeStepHandler} />
      ) : step.currentStep === "SUCCESS" ? (
        <SuccessMessage key={step.currentStep} step={step} />
      ) : (
        <LoginRegisterForm
          key={step.currentStep}
          changeStepHandler={changeStepHandler}
          currentStep={step.currentStep}
        />
      )}
    </div>
  );
};

export default AccountModal;
