import React from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import Button from "../../../shared/Button/Button";
import { useAppState } from "../../../../AppState";
import scss from "./styles.module.scss";

const ForgotPasswordMessage = () => {
  const {
    store: { modalStore },
  } = useAppState();
  return (
    <AccountModalInnerLayout
      title="Check Your Inbox"
      subtitle="We've sent an email with a link to reset your password."
      subtitleMaxWidth={324}
      innerClassName={scss.containerInner}
      className={scss.container}
    >
      <Button
        uppercase
        variant="filled-primary"
        onClick={modalStore.closeModal}
      >
        Confirm
      </Button>
      <p className={scss.text}>
        Please check your inbox, and follow the instructions in the email to
        restore your account access. If you don't see the email within a few
        minutes, be sure to check your spam or junk folder.
      </p>
      <p className={scss.bottomText}>
        Need more help? Contact our support team.
      </p>
    </AccountModalInnerLayout>
  );
};

export default ForgotPasswordMessage;
