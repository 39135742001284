import { memo, SVGProps } from "react";

const PreloaderSmall = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="64"
      version="1.1"
      id="L3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 120 120"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
      {...props}
    >
      <circle
        fill="none"
        stroke="#ffffff"
        strokeWidth="12"
        cx="60"
        cy="60"
        r="44"
      />
      <circle
        fill="#fff"
        stroke="#FD6E03"
        strokeWidth="20"
        cx="18"
        cy="64"
        r="6"
      >
        <animateTransform
          attributeName="transform"
          dur="2s"
          type="rotate"
          from="0 60 58"
          to="360 60 62"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default memo(PreloaderSmall);
