import { useEffect, useState } from "react";
import { useAppState } from "../AppState";

const useFetchMe = () => {
  const [loading, setLoading] = useState(true);

  const {
    store: { preloaderStore },
    service: { userService },
  } = useAppState();

  useEffect(() => {
    userService.fetchMe().finally(() => {
      preloaderStore.showPreloader(false, "GLOBAL");
      setLoading(false);
    });
  }, [preloaderStore, userService]);
  return loading;
};

export default useFetchMe;
