import { UserStore } from "./UserStore";
import { ResolutionStore } from "./ResolutionStore";
import { ModalStore } from "./ModalStore";
import { PreloaderStore } from "./PreloaderStore";
import { AssessmentStore } from "./AssessmentStore";
import { PageStore } from "./PageStore";

export class GlobalStore {
  userStore: UserStore;
  resolutionStore: ResolutionStore;
  modalStore: ModalStore;
  preloaderStore: PreloaderStore;
  assessmentStore: AssessmentStore;
  pageStore: PageStore;

  constructor() {
    this.userStore = new UserStore();
    this.resolutionStore = new ResolutionStore();
    this.modalStore = new ModalStore();
    this.preloaderStore = new PreloaderStore();
    this.assessmentStore = new AssessmentStore();
    this.pageStore = new PageStore();
  }
}
