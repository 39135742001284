import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import useRefData from "../../hooks/useRefData";
import { useAppState } from "../../AppState";
import scss from "./styles.module.scss";

const GlobalPreloader = () => {
  const {
    store: { preloaderStore },
  } = useAppState();
  const timer = useRef<NodeJS.Timer>();
  const scrollTimer = useRef<NodeJS.Timer>();
  const show = preloaderStore.show;
  const type = preloaderStore.type;
  const prevState = useRef(show);
  const typeRef = useRefData(type);

  useEffect(() => {
    const preloader = document.querySelector(".preloader");
    const pageType = typeRef.current === "PAGE";

    if (preloader && show !== prevState.current) {
      if (show) {
        if (pageType) preloader.classList.add(scss.pageType);
        preloader.classList.remove(scss.preloader);
      } else {
        timer.current = setTimeout(() => {
          preloader.classList.add(scss.preloader);
          scrollTimer.current = setTimeout(() => {
            if (pageType) preloader.classList.remove(scss.pageType);
          }, 350);
        }, 250);
      }
      prevState.current = show;
    }

    return () => {
      clearTimeout(timer.current);
      clearTimeout(scrollTimer.current);
    };
  }, [show, typeRef]);
  return null;
};

export default observer(GlobalPreloader);
