import React from "react";
import cn from "classnames";
import CompletedSmallIcon from "../../../icons/CompletedSmallIcon";
import scss from "./styles.module.scss";

type Props = {
  percentage: number;
  maxPercentage: number;
  circleDiameter?: number;
  strokeWidth?: number;
  radius?: number;
  title?: string | number;
  active?: boolean;
};

const CircleProgressBar = ({
  percentage,
  maxPercentage = 100,
  strokeWidth = 8,
  circleDiameter = 56,
  radius = 24,
  title,
  active,
}: Props) => {
  const dashArray = radius * Math.PI * 2;
  const completed =
    !!percentage && !!maxPercentage && percentage === maxPercentage;

  const dashOffset =
    active || percentage
      ? dashArray - (dashArray * percentage) / maxPercentage
      : dashArray;

  return (
    <div
      style={{ width: circleDiameter, height: circleDiameter }}
      className={scss.circleContainer}
    >
      <svg
        width={circleDiameter}
        height={circleDiameter}
        viewBox={`0 0 ${circleDiameter} ${circleDiameter}`}
      >
        <circle
          cx={circleDiameter / 2}
          cy={circleDiameter / 2}
          strokeWidth={`${
            active || completed ? strokeWidth : strokeWidth / 2
          }px`}
          r={radius}
          className={scss.circleBackground}
          fill="none"
        />
        <circle
          cx={circleDiameter / 2}
          cy={circleDiameter / 2}
          strokeWidth={`${
            active || completed ? strokeWidth : strokeWidth / 2
          }px`}
          r={radius}
          className={scss.circleProgress}
          fill="none"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform={`rotate(-90 ${circleDiameter / 2} ${circleDiameter / 2})`}
        />
      </svg>
      <span
        className={cn(scss.circleText, {
          [scss.activeText]: active,
          [scss.show]: !completed,
        })}
      >
        {title}
      </span>
      <CompletedSmallIcon
        className={cn(scss.completedIcon, { [scss.show]: completed })}
      />
    </div>
  );
};
export default CircleProgressBar;
