import React, { ReactNode, useEffect, useRef } from "react";
import cn from "classnames";
import QuickStartProgress from "../QuickStartProgress/QuickStartProgress";
import { useAppState } from "../../../../AppState";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  title?: string;
  subtitle?: string;
  progress?: {
    maxSteps: number;
    currentStep: number;
  };
};

const QuickStartModalInnerLayout = ({
  children,
  className,
  title,
  subtitle,
  innerClassName,
  progress,
}: Props) => {
  const {
    store: { resolutionStore },
  } = useAppState();
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = resolutionStore.isMobile;

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        scss.container,
        {
          [scss.show]: mount.current,
        },
        className,
      )}
    >
      {isMobile && <p className={cn(scss.title, scss.titleMobile)}>{title}</p>}
      {progress && isMobile ? (
        <QuickStartProgress
          maxSteps={progress.maxSteps}
          stepNumber={progress.currentStep}
        />
      ) : null}
      <div className={scss.asideWrapper}>
        <p className={scss.subtitle}>{subtitle}</p>
        {isMobile ? (
          <img
            className={scss.imgMobile}
            src="/assets/modal-img-mobile.webp"
            alt="girl_img"
          />
        ) : (
          <img
            className={scss.img}
            src="/assets/modal-img.webp"
            alt="girl_img"
          />
        )}
      </div>
      <div className={scss.contentWrapper}>
        {!isMobile && <p className={scss.title}>{title}</p>}
        {progress && !isMobile ? (
          <QuickStartProgress
            className={scss.progress}
            maxSteps={progress.maxSteps}
            stepNumber={progress.currentStep}
          />
        ) : null}
        <div className={cn(scss.contentInnerWrapper, innerClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default observer(QuickStartModalInnerLayout);
