const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDateWithDay = (date: string) => {
  if (!date) return null;
  const _date = new Date(date);
  return `${
    months[_date.getMonth()]
  } ${_date.getDate()}, ${_date.getFullYear()}`;
};
