import React from "react";
import CareerButton from "../CareerButton/CareerButton";
import { CareerShortInfoDto } from "../../../../../../utils/getCareersShortInfo";
import scss from "./styles.module.scss";

type Props = {
  groupIndex: number;
  title: string;
  careers: CareerShortInfoDto[];
  changeActiveCareerHandler: (groupIndex: number, careerIndex: number) => void;
  activeCareerState: {
    groupIndex: number;
    careerIndex: number;
  };
};

const CareersGroup = ({
  groupIndex,
  careers,
  title,
  changeActiveCareerHandler,
  activeCareerState,
}: Props) => {
  return (
    <div className={scss.container}>
      <p className={scss.title}>{title}</p>
      <div className={scss.careersWrapper}>
        {careers.map((career, i) => (
          <CareerButton
            isActive={
              i === activeCareerState.careerIndex &&
              groupIndex === activeCareerState.groupIndex
            }
            groupIndex={groupIndex}
            careerIndex={i}
            title={career.title}
            industryTitle={career.industryTitle}
            percentage={career.percentage}
            key={i}
            onClick={changeActiveCareerHandler}
          />
        ))}
      </div>
    </div>
  );
};

export default CareersGroup;
