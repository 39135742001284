import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useFetchAssessment } from "../../api/query";
import ProgressSection from "./components/ProgressSection/ProgressSection";
import QuestionSection from "./components/QuestionSection/QuestionSection";
import ActionsSection from "./components/ActionsSection/ActionsSection";
import { useAppState } from "../../AppState";
import { observer } from "mobx-react-lite";
import CompletedSection from "./components/QuestionSection/components/CompletedSection/CompletedSection";
import CloseIcon from "../../icons/CloseIcon";
import NotFound from "../NotFound/NotFound";
import scss from "./styles.module.scss";

const AssessmentPage = observer(() => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const steps = assessmentStore.steps;
  const completed = assessmentStore.assessment.status === "FINISHED";
  const initStepIndex = steps.findIndex((step) => !step.completed);
  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(initStepIndex);

  return (
    <div className={scss.pageContainer}>
      <div className={scss.container}>
        <div className={scss.containerInner}>
          <NavLink className={scss.closeIcon} to="/">
            <CloseIcon />
          </NavLink>
          <ProgressSection currentStepId={steps[currentStepIndex]?.id} />
          {completed ? (
            <CompletedSection />
          ) : (
            <div className={scss.contentSection}>
              <QuestionSection
                step={steps[currentStepIndex]}
                currentStepIndex={currentStepIndex}
              />
              <ActionsSection
                stepCategory={steps[currentStepIndex]?.category}
                maxSteps={steps.length}
                currentStepIndex={currentStepIndex}
                setActiveStepIndex={setCurrentStepIndex}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const Assessment = () => {
  const { id } = useParams();

  const { data, status } = useFetchAssessment(id as string);

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return <AssessmentPage />;
};

export default Assessment;
