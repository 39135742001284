import { useQuery } from "react-query";
import {
  fetchAssessment,
  fetchCareerMatch,
  fetchCareerMatches,
  fetchHomePage,
} from "./api";
import usePreloader from "../hooks/usePreloader";
import { useAppState } from "../AppState";

export const useFetchHomePage = () => {
  const {
    store: { pageStore },
  } = useAppState();

  return useQuery(
    ["page"],
    () => {
      // preloader(true);
      return fetchHomePage();
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        pageStore.setPrice(data.price);
      },
    },
  );
};

export const useFetchAssessment = (id: string) => {
  const {
    store: { assessmentStore },
  } = useAppState();
  const preloader = usePreloader();

  return useQuery(
    ["assessment", id],
    () => {
      preloader(true);
      return fetchAssessment(id);
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,

      onSuccess: (data) => {
        assessmentStore.setAssessment(data);
      },

      onSettled: () => {
        preloader(false);
      },
    },
  );
};

export const useFetchCareerMatch = (id: string) => {
  const preloader = usePreloader();

  return useQuery(
    ["career-match", id],
    () => {
      preloader(true);
      return fetchCareerMatch(id);
    },
    {
      staleTime: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,

      onSettled: () => {
        preloader(false);
      },
    },
  );
};

export const useFetchCareerMatches = () => {
  const preloader = usePreloader();

  return useQuery(
    ["career-matches"],
    () => {
      preloader(true);
      return fetchCareerMatches();
    },
    {
      // staleTime: Infinity,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // retry: false,
      // cacheTime: 0,

      onSettled: () => {
        preloader(false);
      },
    },
  );
};
