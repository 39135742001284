import React, { useEffect } from "react";
import { CareerMatchDto } from "../../../../api/data-contracts";
import QuickStartModalInnerLayout from "../QuickStartModalInnerLayout/QuickStartModalInnerLayout";
import Button from "../../../shared/Button/Button";
import CompletedBigIcon from "../../../../icons/CompletedBigIcon";
import { useAppState } from "../../../../AppState";
import scss from "./styles.module.scss";

type Props = {
  activeCareerMatch: CareerMatchDto;
};

const PaymentSuccess = ({ activeCareerMatch }: Props) => {
  const {
    store: { userStore },
  } = useAppState();

  useEffect(() => {
    return () => {
      userStore.setActiveAssessmentId(activeCareerMatch.userAssessmentId);
    };
  }, [activeCareerMatch, userStore]);

  return (
    <QuickStartModalInnerLayout
      title="Payment completed"
      subtitle="The payment was successful, now you can start testing. Enjoy!"
      progress={{ currentStep: 3, maxSteps: 2 }}
      innerClassName={scss.container}
    >
      <CompletedBigIcon className={scss.completeIcon} />
      <Button
        variant="filled-primary"
        uppercase
        type="innerLink"
        to={`/assessment/${activeCareerMatch.userAssessmentId}`}
      >
        Start assessment
      </Button>
    </QuickStartModalInnerLayout>
  );
};

export default PaymentSuccess;
