import React from "react";
import Footer from "../../components/Footer/Footer";
import { useFetchCareerMatches } from "../../api/query";
import { CareerMatchesCardsResDto } from "../../api/data-contracts";
import CareerMatchCard from "./components/CareerMatchCard/CareerMatchCard";
import NotFound from "../NotFound/NotFound";
import scss from "./styles.module.scss";

const CareerMatchesPage = ({ careerMatches }: CareerMatchesCardsResDto) => {
  const isCareerMatches = careerMatches.length;
  return (
    <div className={scss.pageContainer}>
      <div className={scss.container}>
        <div className={scss.mainSection}>
          <h1 className={scss.title}>Career Match</h1>
          <div className={scss.cardsWrapper}>
            {isCareerMatches ? (
              careerMatches.map((careerMatch) => (
                <CareerMatchCard
                  careerMatch={careerMatch}
                  key={careerMatch.id}
                />
              ))
            ) : (
              <div className={scss.emptyText}>
                You have no completed assessments yet
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const CareerMatches = () => {
  const { data, status } = useFetchCareerMatches();

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return <CareerMatchesPage careerMatches={data.careerMatches} />;
};

export default CareerMatches;
