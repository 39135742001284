import React, { useEffect, useRef } from "react";
import { ReviewDto } from "../../../../../../api/data-contracts";
import cn from "classnames";
import LikeIcon from "../../../../../../icons/LikeIcon";
import scss from "./styles.module.scss";

type Props = {
  card: ReviewDto;
  className?: string;
  mountWithAnimate?: boolean;
};

const ReviewCard = ({ className, card, mountWithAnimate }: Props) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mountWithAnimate) {
      const timeout = setTimeout(() => {
        ref.current?.classList.add(scss.show);
        mount.current = true;
      }, 50);

      return () => {
        clearTimeout(timeout);
      };
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={cn(
        scss.container,
        {
          [scss.show]: mount.current || !mountWithAnimate,
        },
        className,
      )}
      ref={ref}
    >
      <div className={scss.contentWrapper}>
        <div className={scss.textWrapper}>
          <h3 className={scss.title}>{card.title}</h3>
          <p className={scss.description}>{card.description}</p>
        </div>
        <img
          className={scss.avatar}
          src={card.author.imageSrc}
          alt={card.author.name}
        />
      </div>
      <div className={scss.authorWrapper}>
        <img
          className={scss.avatarMobile}
          src={card.author.imageSrc}
          alt={card.author.name}
        />
        <p className={scss.authorName}>{card.author.name}</p>
        <p className={scss.authorProfession}>{card.author.position}</p>
        <LikeIcon className={scss.icon} />
      </div>
    </div>
  );
};

export default ReviewCard;
