import React from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import Button from "../../../shared/Button/Button";
import { useAppState } from "../../../../AppState";
import { AccountSteps } from "../../AccountModal";
import scss from "./styles.module.scss";

type Props = {
  changeStepHandler: (step: AccountSteps) => () => void;
};

const LogOut = ({ changeStepHandler }: Props) => {
  const {
    store: { modalStore },
    service: { authService },
  } = useAppState();

  const logoutHandler = () => {
    authService.logOut();
    modalStore.closeModal();
  };

  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title="Log Out"
      subtitle={`Are you sure you want to log out? <br/> You can return at any time.`}
    >
      <div className={scss.actionsWrapper}>
        <Button uppercase variant="filled-primary" onClick={logoutHandler}>
          Log out
        </Button>
        <Button
          uppercase
          variant="outline"
          onClick={changeStepHandler("PERSONAL")}
        >
          Go back
        </Button>
      </div>
    </AccountModalInnerLayout>
  );
};

export default LogOut;
