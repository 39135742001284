import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const axiosClient = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

export const authAxiosClient = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});
