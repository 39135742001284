import { memo, SVGProps } from "react";

const ErrorBigIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="192"
      height="195"
      viewBox="0 0 192 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.1042 181.766C62.2001 189.982 78.555 194.683 95.9962 194.683C149.016 194.683 192 151.243 192 97.6555C192 44.0684 149.019 0.627441 96 0.627441C42.9807 0.627441 0 44.0684 0 97.6555C0 115.284 4.65113 131.814 12.7807 146.06L12.8122 146.116C13.5944 147.486 13.9889 148.178 14.1676 148.831C14.3361 149.447 14.3831 150.001 14.34 150.639C14.2936 151.324 14.0651 152.035 13.5966 153.455L5.39583 178.321L5.38551 178.354C3.65523 183.6 2.79009 186.223 3.40676 187.971C3.94446 189.495 5.13797 190.698 6.64583 191.241C8.37137 191.863 10.9551 190.993 16.1232 189.252L16.1875 189.228L40.7898 180.939C42.1907 180.467 42.9028 180.228 43.5796 180.181C44.2107 180.137 44.7549 180.195 45.3646 180.365C46.0126 180.546 46.6969 180.945 48.0601 181.74L48.1042 181.766Z"
        fill="#F0F5F3"
      />
      <path
        d="M72 121.912L96 97.6553M96 97.6553L120 73.3983M96 97.6553L72 73.3983M96 97.6553L120 121.912"
        stroke="#EB001B"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(ErrorBigIcon);
