import React, { useEffect } from "react";
import MainSection from "./components/MainSection/MainSection";
import AssessmentGuideSection from "./components/AssessmentGuideSection/AssessmentGuideSection";
import ReviewsSection from "./components/ReviewsSection/ReviewsSection";
import { useFetchHomePage } from "../../api/query";
import { HomePageResDto } from "../../api/data-contracts";
import { useClearQuery, useGetQueryParam } from "../../hooks/useGetQueryParam";
import { useAppState } from "../../AppState";
import AccountModal from "../../components/AccountModal/AccountModal";
import Footer from "../../components/Footer/Footer";
import NotFound from "../NotFound/NotFound";
import QuickStartModal from "../../components/QuickStartModal/QuickStartModal";
import scss from "./styles.module.scss";

const HomePage = (props: HomePageResDto) => {
  const {
    service: { assessmentService },
    store: { modalStore },
  } = useAppState();

  const {
    paymentSuccess,
    paypalPaymentSuccess,
    resetPasswordToken,
    payPalOrderId,
  } = useGetQueryParam();

  useEffect(() => {
    const checkPaymentStatusAndOpenModal = async () => {
      if (paypalPaymentSuccess === "true" && payPalOrderId) {
        const result = await assessmentService.checkPaypalOrderStatus({
          orderId: payPalOrderId,
        });

        if (result?.paymentStatus === "PENDING") {
          modalStore.openModal(<QuickStartModal isPaymentPending />, {
            modalClassName: scss.modal,
          });
        }
      }
    };

    checkPaymentStatusAndOpenModal();
  }, [paypalPaymentSuccess, payPalOrderId, modalStore, assessmentService]);

  useClearQuery();

  useEffect(() => {
    if (resetPasswordToken) {
      modalStore.openModal(
        <AccountModal startStep="RESET_PASSWORD" code={resetPasswordToken} />,
        {
          modalClassName: scss.modal,
          preventCloseByRouteChange: true,
        },
      );
    }

    if (paymentSuccess || paypalPaymentSuccess === "false") {
      modalStore.openModal(
        <QuickStartModal isPaymentSuccess={paymentSuccess === "true"} />,
        {
          modalClassName: scss.modal,
        },
      );
    }
  }, [resetPasswordToken, modalStore, paymentSuccess, paypalPaymentSuccess]);

  return (
    <div className={scss.container}>
      <MainSection videos={props.videos} />
      <AssessmentGuideSection
        currentPrice={props.price.currentPrice}
        oldPrice={props.price.oldPrice}
      />
      <ReviewsSection reviews={props.reviews} />
      <Footer />
    </div>
  );
};

const Home = () => {
  const { data, status } = useFetchHomePage();

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return (
    <HomePage reviews={data.reviews} price={data.price} videos={data.videos} />
  );
};

export default Home;
