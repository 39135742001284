import { memo, SVGProps } from "react";

const PaymentIconAmericanExpress = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1331_438)">
        <path
          d="M0 0.022522H17.9553V9.71533L17.0666 11.104L17.9553 12.3399V17.9778H0V8.84048L0.555462 8.2017L0 7.59069V0.022522Z"
          fill="#016FD0"
        />
        <path
          d="M3.48633 12.5341V9.71515H6.47109L6.79133 10.1326L7.12215 9.71515H17.9561V12.3397C17.9561 12.3397 17.6728 12.5313 17.3451 12.5341H11.3461L10.9851 12.0897V12.5341H9.80193V11.7756C9.80193 11.7756 9.64031 11.8815 9.2909 11.8815H8.8882V12.5341H7.09683L6.77705 12.1077L6.45237 12.5341H3.48633Z"
          fill="white"
        />
        <path
          d="M0 7.59051L0.673076 6.02133H1.83709L2.21907 6.90032V6.02133H3.66605L3.89344 6.65664L4.11389 6.02133H10.6093V6.34072C10.6093 6.34072 10.9508 6.02133 11.5119 6.02133L13.6195 6.0287L13.9949 6.89618V6.02133H15.2058L15.539 6.51963V6.02133H16.7611V8.8403H15.539L15.2197 8.34038V8.8403H13.4406L13.2617 8.39593H12.7834L12.6074 8.8403H11.4009C10.918 8.8403 10.6093 8.52743 10.6093 8.52743V8.8403H8.79018L8.42913 8.39593V8.8403H1.66465L1.48586 8.39593H1.00911L0.83158 8.8403H0V7.59051Z"
          fill="white"
        />
        <path
          d="M0.911739 6.36885L0.00390625 8.47961H0.594952L0.762459 8.05694H1.73625L1.90289 8.47961H2.50696L1.59999 6.36885H0.911739ZM1.24762 6.86009L1.54445 7.59868H0.949927L1.24762 6.86009Z"
          fill="#016FD0"
        />
        <path
          d="M2.56836 8.47925V6.3685L3.40827 6.37162L3.89679 7.7325L4.37361 6.3685H5.2068V8.47925H4.67911V6.92396L4.11975 8.47925H3.65697L3.09605 6.92396V8.47925H2.56836Z"
          fill="#016FD0"
        />
        <path
          d="M5.56836 8.47925V6.3685H7.29029V6.84064H6.1016V7.20169H7.26252V7.64606H6.1016V8.021H7.29029V8.47925H5.56836Z"
          fill="#016FD0"
        />
        <path
          d="M7.5957 6.36885V8.47961H8.12339V7.72973H8.34558L8.97828 8.47961H9.62314L8.92881 7.70196C9.21377 7.67791 9.50771 7.43334 9.50771 7.05363C9.50771 6.60946 9.15908 6.36885 8.76998 6.36885H7.5957ZM8.12339 6.84099H8.72659C8.87129 6.84099 8.97655 6.95418 8.97655 7.06318C8.97655 7.20341 8.84017 7.28536 8.7344 7.28536H8.12339V6.84099Z"
          fill="#016FD0"
        />
        <path
          d="M10.2615 8.47925H9.72266V6.3685H10.2615V8.47925Z"
          fill="#016FD0"
        />
        <path
          d="M11.5402 8.47925H11.4239C10.8612 8.47925 10.5195 8.03593 10.5195 7.43256C10.5195 6.81428 10.8574 6.3685 11.568 6.3685H12.1512V6.86842H11.5466C11.2582 6.86842 11.0542 7.09354 11.0542 7.43776C11.0542 7.84654 11.2874 8.01822 11.6235 8.01822H11.7624L11.5402 8.47925Z"
          fill="#016FD0"
        />
        <path
          d="M12.6871 6.36885L11.7793 8.47961H12.3703L12.5378 8.05694H13.5116L13.6783 8.47961H14.2823L13.3754 6.36885H12.6871ZM13.023 6.86009L13.3198 7.59868H12.7253L13.023 6.86009Z"
          fill="#016FD0"
        />
        <path
          d="M14.3457 8.47925V6.3685H15.0166L15.8732 7.69467V6.3685H16.4009V8.47925H15.7517L14.8734 7.11837V8.47925H14.3457Z"
          fill="#016FD0"
        />
        <path
          d="M3.8457 12.1731V10.0623H5.56763V10.5345H4.37895V10.8955H5.53986V11.3399H4.37895V11.7148H5.56763V12.1731H3.8457Z"
          fill="#016FD0"
        />
        <path
          d="M12.2832 12.1731V10.0623H14.0051V10.5345H12.8164V10.8955H13.9718V11.3399H12.8164V11.7148H14.0051V12.1731H12.2832Z"
          fill="#016FD0"
        />
        <path
          d="M5.6352 12.1731L6.4736 11.1307L5.61523 10.0623H6.28005L6.79125 10.7228L7.30419 10.0623H7.94297L7.09589 11.1177L7.93583 12.1731H7.27112L6.77476 11.523L6.29047 12.1731H5.6352Z"
          fill="#016FD0"
        />
        <path
          d="M7.99805 10.0627V12.1734H8.53962V11.5069H9.09508C9.56508 11.5069 9.92133 11.2575 9.92133 10.7726C9.92133 10.3709 9.64192 10.0627 9.16365 10.0627H7.99805ZM8.53962 10.54H9.12459C9.27643 10.54 9.38497 10.6331 9.38497 10.783C9.38497 10.9239 9.27698 11.026 9.12286 11.026H8.53962V10.54Z"
          fill="#016FD0"
        />
        <path
          d="M10.1504 10.0623V12.1731H10.6781V11.4232H10.9003L11.533 12.1731H12.1778L11.4835 11.3954C11.7685 11.3714 12.0624 11.1268 12.0624 10.7471C12.0624 10.3029 11.7138 10.0623 11.3247 10.0623H10.1504ZM10.6781 10.5345H11.2813C11.426 10.5345 11.5312 10.6476 11.5312 10.7566C11.5312 10.8969 11.3949 10.9788 11.2891 10.9788H10.6781V10.5345Z"
          fill="#016FD0"
        />
        <path
          d="M14.2506 12.1731V11.7148H15.3067C15.4629 11.7148 15.5306 11.6304 15.5306 11.5378C15.5306 11.449 15.4631 11.3593 15.3067 11.3593H14.8294C14.4146 11.3593 14.1836 11.1066 14.1836 10.7271C14.1836 10.3887 14.3952 10.0623 15.0116 10.0623H16.0392L15.817 10.5372H14.9283C14.7584 10.5372 14.7061 10.6264 14.7061 10.7115C14.7061 10.799 14.7707 10.8955 14.9005 10.8955H15.4004C15.8628 10.8955 16.0635 11.1578 16.0635 11.5013C16.0635 11.8706 15.8399 12.1731 15.3752 12.1731H14.2506Z"
          fill="#016FD0"
        />
        <path
          d="M16.1861 12.1731V11.7148H17.2422C17.3985 11.7148 17.4661 11.6304 17.4661 11.5378C17.4661 11.449 17.3987 11.3593 17.2422 11.3593H16.765C16.3502 11.3593 16.1191 11.1066 16.1191 10.7271C16.1191 10.3887 16.3307 10.0623 16.9471 10.0623H17.9747L17.7525 10.5372H16.8638C16.6939 10.5372 16.6416 10.6264 16.6416 10.7115C16.6416 10.799 16.7062 10.8955 16.836 10.8955H17.336C17.7984 10.8955 17.999 11.1578 17.999 11.5013C17.999 11.8706 17.7754 12.1731 17.3108 12.1731H16.1861Z"
          fill="#016FD0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_438">
          <rect y="0.0223389" width="18" height="17.9553" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(PaymentIconAmericanExpress);
