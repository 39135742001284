import React from "react";
import { useFetchCareerMatch } from "../../api/query";
import { useParams } from "react-router-dom";
import CareerMatchTopSection from "./Components/CareerMatchTopSection/CareerMatchTopSection";
import { CareerMatchResultDto } from "../../api/data-contracts";
import CareerMatchMainSection from "./Components/CareerMatchMainSection/CareerMatchMainSection";
import NotFound from "../NotFound/NotFound";
import scss from "./styles.module.scss";

type Props = {
  careerMatch: CareerMatchResultDto;
};

const CareerMatchPage = ({ careerMatch }: Props) => {
  return (
    <div className={scss.pageContainer}>
      <div className={scss.container}>
        <CareerMatchTopSection personalities={careerMatch.personalities} />
        <CareerMatchMainSection
          careersFromSelectedIndustries={
            careerMatch.careersFromSelectedIndustries
          }
          careersFromOtherIndustries={careerMatch.careersFromOtherIndustries}
        />
      </div>
    </div>
  );
};

const CareerMatch = () => {
  const { id } = useParams();

  const { data, status } = useFetchCareerMatch(id as string);

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return <CareerMatchPage careerMatch={data.careerMatch} />;
};

export default CareerMatch;
