import React, { useEffect, useMemo, useRef } from "react";
import { CareerInfoDto } from "../../../../../../api/data-contracts";
import cn from "classnames";
import Caption from "../../../../../../components/shared/Caption/Caption";
import { CareerFeatureDto } from "../CareerFeatures/CareerFeatureItem";
import CareerFeatures from "../CareerFeatures/CareerFeatures";
import CareerSalaries from "../CareerSalaries/CareerSalaries";
import scss from "./styles.module.scss";

type Props = {
  career: CareerInfoDto;
  className?: string;
  titleHidden?: boolean;
};

const CareerInfo = ({ career, className, titleHidden }: Props) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const features: CareerFeatureDto[] = useMemo(
    () => [
      {
        title: "Education Requirements",
        description: career.educationRequirements,
      },
      {
        title: "Other Qualifications",
        description: career.otherQualifications,
      },
      {
        title: "Job Responsibilities",
        description: career.jobResponsibilities,
      },
      {
        title: "Physical Requirements",
        description: career.physicalRequirements,
      },
    ],
    [
      career.educationRequirements,
      career.jobResponsibilities,
      career.otherQualifications,
      career.physicalRequirements,
    ],
  );

  if (!career) return null;

  return (
    <div
      ref={ref}
      className={cn(
        scss.container,
        {
          [scss.show]: mount.current,
        },
        className,
      )}
    >
      <div className={scss.titleAndSalaryWrapper}>
        {!titleHidden ? <h2 className={scss.title}>{career.title}</h2> : null}
        {career.averageSalary && !titleHidden ? (
          <CareerSalaries salaries={career.averageSalary} />
        ) : null}
      </div>
      <div className={cn(scss.personalitiesWrapper, "hiddenScroll")}>
        {career.personalities.map((personality, i) => (
          <Caption
            variant="green"
            className={scss.caption}
            title={personality}
            key={i}
          />
        ))}
      </div>
      {titleHidden && career.averageSalary ? (
        <CareerSalaries
          className={scss.salariesWrapper}
          salaries={career.averageSalary}
        />
      ) : null}
      <div className={scss.descriptionsWrapper}>
        <p className={scss.industryDescription}>{career.industryDescription}</p>
        <p className={scss.jobGroupDescription}>{career.jobGroupDescription}</p>
        <p className={scss.careerDescription}>{career.description}</p>
      </div>
      <CareerFeatures features={features} className={scss.featuresWrapper} />
    </div>
  );
};

export default CareerInfo;
