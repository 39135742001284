import React, {
  HTMLInputTypeAttribute,
  ReactNode,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import scss from "./styles.module.scss";
import EyeIcon from "../../../icons/EyeIcon";
import EyeClosedIcon from "../../../icons/EyeClosedIcon";

type Props = {
  type?: HTMLInputTypeAttribute;
  placeholder: string;
  icon?: ReactNode;
  errorText?: string;
  value: string;
  onChange: (value: string, target: HTMLInputElement) => void;
  name?: string;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
};

const Input = ({
  type = "text",
  placeholder,
  icon,
  value,
  onChange,
  errorText,
  name,
  fullWidth,
  className,
  disabled,
}: Props) => {
  const ref = useRef<HTMLInputElement>(document.createElement("input"));
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);

  const showHideHandler = () => setShow((prevState) => !prevState);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, e.target);
  };

  const onClick = () => {
    setActive(true);
    ref.current.focus();
  };

  const onBlur = () => {
    setActive(false);
  };

  const inputType = type === "password" ? (show ? "text" : "password") : type;

  return (
    <div
      className={cn(
        scss.inputWrapper,
        {
          [scss.active]: active,
          [scss.error]: !active && errorText,
          [scss.disableHover]: errorText || active,
          [scss.fullWidth]: fullWidth,
          [scss.disabled]: disabled,
        },
        className,
      )}
      onClick={onClick}
    >
      {icon && <div className={scss.iconWrapper}>{icon}</div>}
      <input
        disabled={disabled}
        name={name}
        ref={ref}
        className={cn(scss.input, { [scss.withIcon]: icon })}
        type={inputType}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlur}
      />
      {type === "password" && (
        <div className={scss.showHideIconWrapper} onClick={showHideHandler}>
          <EyeIcon className={cn(scss.showHideIcon, { [scss.show]: show })} />
          <EyeClosedIcon
            className={cn(scss.showHideIcon, { [scss.show]: !show })}
          />
        </div>
      )}
      <span className={scss.errorText}>{errorText}</span>
    </div>
  );
};

export default Input;
