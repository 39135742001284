import { observer } from "mobx-react-lite";
import { CSSProperties, MouseEvent, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { useAppState } from "../../AppState";
import { useLocation } from "react-router-dom";
import scss from "./styles.module.scss";

const Modal = observer(() => {
  const {
    store: { modalStore },
  } = useAppState();
  const state = modalStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));
  const mount = useRef<boolean>(false);
  const { pathname } = useLocation();

  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth;
  };

  useEffect(() => {
    if (!state.preventCloseByRouteChange && mount.current) {
      modalStore.closeModal();
    }
    mount.current = true;
  }, [modalStore, pathname, state.preventCloseByRouteChange]);

  useEffect(() => {
    root.current.id = "modal";
    document.body.append(root.current);
  }, []);

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth();
    const header = document.querySelector("header");
    if (state.opened) {
      document.body.classList.add("modalOpen");
      if (header) header.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.classList.remove("modalOpen");
      if (header) header.style.paddingRight = ``;
      document.body.style.paddingRight = "";
    }
  }, [state.opened]);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) modalStore.closeModal();
  };

  const style = {
    "--modal-overlay-bg": state.overlayColor || "rgba(0,0,0,0.2)",
  } as CSSProperties;

  return createPortal(
    <div
      style={style}
      className={classNames(scss.overlay, state.overlayClassName, {
        [scss.active]: state.opened,
      })}
      onClick={onOutsideClick}
    >
      <div
        className={classNames(
          scss.modal,
          {
            [scss.active]: state.opened,
          },
          state.modalClassName,
        )}
      >
        {state.content}
      </div>
    </div>,
    root.current,
  );
});

export default Modal;
