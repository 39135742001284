import React from "react";
import cn from "classnames";
import ArrowLong from "../../../icons/ArrowLong";
import scss from "./styles.module.scss";

export type CardProps = {
  title: string;
  caption: string;
  actionTitle: string;
  onClick?: () => void;
  variant: "green" | "orange";
  className?: string;
};

const Card = ({
  variant,
  actionTitle,
  title,
  caption,
  className,
  onClick,
}: CardProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(scss.container, scss[variant], className)}
    >
      <p className={scss.caption}>{caption}</p>
      <p className={scss.title}>{title}</p>
      <p className={scss.actionTitle}>
        {actionTitle} <ArrowLong className={scss.arrowIcon} />
      </p>
    </div>
  );
};

export default Card;
