import React from "react";
import { CareerMatchCardDto } from "../../../../api/data-contracts";
import { getDateWithDay } from "../../../../utils/dateUtils";
import Caption from "../../../../components/shared/Caption/Caption";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import PersonalityItem from "../PersonalityItem/PersonalityItem";
import CareerItem from "../CareerItem/CareerItem";
import scss from "./styles.module.scss";

type Props = {
  careerMatch: CareerMatchCardDto;
};

const CareerMatchCard = ({ careerMatch }: Props) => {
  const shownCareers = 3;
  return (
    <NavLink to={`/career-match/${careerMatch.id}`} className={scss.container}>
      <div className={scss.titleSection}>
        <div className={scss.titleAndDateWrapper}>
          <h3 className={scss.title}>Guidenar Career Assessment</h3>
          <p className={scss.date}>{getDateWithDay(careerMatch.finishDate)}</p>
        </div>
        {!careerMatch.viewed ? (
          <Caption className={scss.new} title="NEW" />
        ) : null}
      </div>
      <div className={scss.separator} />
      <div className={scss.personalitiesSection}>
        <p className={scss.sectionTitle}>Your answers suggest you are:</p>
        <div className={scss.personalitiesInnerWrapper}>
          {careerMatch.personalities.map((item, i) => (
            <PersonalityItem personality={item} key={i} />
          ))}
        </div>
      </div>
      <div className={scss.separator} />
      <div className={scss.careersSection}>
        <p className={scss.sectionTitle}>
          Based on your interests, we've identified possibilities for you
          within:
        </p>
        <div className={cn(scss.careersInnerWrapper, "hiddenScroll")}>
          {careerMatch.careers.map((el, i) =>
            i < shownCareers ? <CareerItem career={el} key={i} /> : null,
          )}
          <div className={cn(scss.showMore, scss.desktop)}>
            more +{careerMatch.careers.length - shownCareers}
          </div>
        </div>
        <div className={cn(scss.showMore, scss.mobile)}>
          More <span>+{careerMatch.careers.length - shownCareers}</span>
        </div>
      </div>
    </NavLink>
  );
};

export default CareerMatchCard;
