export enum ErrorType {
  VALIDATION_ERROR = "ValidationError",
}

export type ReviewDto = {
  id: string;
  author: {
    name: string;
    imageSrc: string;
    position: string;
  };
  title: string;
  description: string;
};

export type PriceDto = {
  oldPrice: number;
  currentPrice: number;
};

export type HomePageResDto = {
  price: PriceDto;
  reviews: Array<ReviewDto>;
  videos: string[];
};

export type PaymentMethodDto = "CARD" | "PAY_PAL";

export type PaymentDto = {
  id: string;
  title: string;
  date: string;
  summary: number;
  paymentMethod: PaymentMethodDto;
  receiptURL?: string;
};

export type UserDto = {
  id: string;
  email: string;
  emailConfirmed: boolean;
  careerMatchesCount: number;
  activeAssessmentId?: string;
  payments: Array<PaymentDto>;
};

export type LoginReqDto = {
  identifier: string;
  password: string;
};

export type LoginResDto = {
  jwt: string;
  user: {
    email: string;
    profileUid: string;
    emailConfirmed: boolean;
  };
};

export type RegisterReqDto = {
  email: string;
  password: string;
};

export type RegisterResDto = LoginResDto;

export type ForgotPasswordReqDto = {
  email: string;
};

export type CheckResetPasswordTokenReqDto = {
  code: string;
};

export type ResetPasswordReqDto = {
  code: string;
  password: string;
  passwordConfirmation: string;
};

export type ResetPasswordResDto = LoginResDto;

export type ChangePasswordReqDto = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type ChangePasswordResDto = LoginResDto;

export type PaymentStatusDto = "NOT_PAID" | "PENDING" | "PAID";

export type CareerMatchDto = {
  id: string;
  paymentStatus: PaymentStatusDto;
  startDate: string;
  finishDate: string;
  status: "ACTIVE" | "FINISHED";
  title: string;
  userAssessmentId: string;
};

export type CreateCareerMatchResDto = CareerMatchDto;

export type CreatePaymentReqDto = {
  careerMatchId: string;
  paymentMethod?: PaymentMethodDto;
  code?: string;
};

export type CreatePaymentResDto = {
  sessionId?: string;
  paymentStatus: PaymentStatusDto;
  paypalApprovalUrl?: string;
  error?: string;
};

export type IndustryDto = {
  id: string;
  title: string;
  description: string;
};

export type JobGroupDto = {
  id: string;
  title: string;
  description: string;
};

export type SubjectDto = {
  id: string;
  title: string;
  descriptions: string;
};

export type PersonalityDto = {
  id: string;
  title: string;
};

export type SkillDto = {
  id: string;
  title: string;
};

export type ValueDto = {
  id: string;
  title: string;
  description: string;
};

export type AssessmentStepSelectedOptionValueDto =
  | { rank: number }
  | { selected: boolean };

export type AssessmentStepSelectedOptionsDto = Record<
  string,
  AssessmentStepSelectedOptionValueDto
>;

export type AssessmentStepCategoryDto =
  | "INDUSTRY_SELECT_INDUSTRIES"
  | "INDUSTRY_SELECT_RANK"
  | "INDUSTRY_SELECT_JOB_GROUPS"
  | "PERSONALITY"
  | "VALUES"
  | "SUBJECTS";

// export enum AssessmentStepCategoryDto {
//   INDUSTRY_SELECT_INDUSTRIES = "INDUSTRY_SELECT_INDUSTRIES",
//   INDUSTRY_SELECT_RANK = "INDUSTRY_SELECT_RANK",
//   INDUSTRY_SELECT_JOB_GROUPS = "INDUSTRY_SELECT_JOB_GROUPS",
//   PERSONALITY = "PERSONALITY",
//   VALUES = "VALUES",
//   SUBJECTS = "SUBJECTS",
// }

export type AssessmentStepOptionDto = {
  title: string;
  relationId: string;
  icon?: string;
};

export type AssessmentStepDto = {
  id: number;
  completed: boolean;
  category: AssessmentStepCategoryDto;
  questionTitle: string;
  options: Array<AssessmentStepOptionDto>;
  selectedOptions?: AssessmentStepSelectedOptionsDto;
  maxOptionsCount?: number;
};

export type AssessmentDto = {
  id: string;
  title: string;
  careerMatchId: string;
  steps: Array<AssessmentStepDto>;
  industries: Array<{ industry: IndustryDto; totalPoints: number }>;
  jobGroups: Array<JobGroupDto>;
  personalities: Array<PersonalityDto>;
  subjects: Array<SubjectDto>;
  skills: Array<SkillDto>;
  values: Array<ValueDto>;
  status: "ACTIVE" | "FINISHED";
};

export type AssessmentResDto = AssessmentDto;

export type UpdateAssessmentReqDto = {
  id: string;
  stepId: number;
  category: AssessmentStepCategoryDto;
  selectedOptions: AssessmentStepSelectedOptionsDto;
};

export type UpdateAssessmentResDto = AssessmentDto;

export type PersonalityInfoDto = {
  title: string;
  description: string;
};

export type CareerMainInfoDto = {
  title: string;
  careerPoints: {
    maxPoints: number;
    totalPoints: number;
  };
};

export type CareerMatchCardDto = {
  id: string;
  careers: Array<CareerMainInfoDto>;
  personalities: Array<PersonalityInfoDto>;
  finishDate: string;
  viewed: boolean;
};

export type CareerMatchesCardsResDto = {
  careerMatches: Array<CareerMatchCardDto>;
};

export type CareerSalariesDto = Record<string, string> | null;

export type CareerInfoDto = {
  id: string;
  careerPoints: {
    maxPoints: number;
    totalPoints: number;
  };
  personalities: string[];
  title: string;
  description: string;
  jobGroupTitle: string;
  jobGroupDescription: string;
  industryTitle: string;
  industryDescription: string;
  educationRequirements: string;
  jobResponsibilities: string;
  otherQualifications: string;
  physicalRequirements: string;
  averageSalary: CareerSalariesDto;
};

export type CareerMatchResultDto = {
  id: string;
  careersFromSelectedIndustries: CareerInfoDto[];
  careersFromOtherIndustries: CareerInfoDto[];
  personalities: string[];
};

export type CareerMatchResultResDto = {
  careerMatch: CareerMatchResultDto;
};

export type CheckPaypalOrderStatusReqDto = {
  orderId: string;
};

export type CheckPaypalOrderStatusResDto = {
  paymentStatus: PaymentStatusDto;
};

export type GetStripePaymentModeResDto = {
  stripeLiveMode: boolean;
};

export type CheckPromoCodeReqDto = {
  code: string;
};

export type CheckPromoCodeResDto = {
  discount: number;
  code: string;
};
