import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  careerIndex: number;
  groupIndex: number;
  title: string;
  industryTitle: string;
  percentage: number;
  onClick: (groupIndex: number, careerIndex: number) => void;
  isActive: boolean;
};

const CareerButton = ({
  percentage,
  industryTitle,
  title,
  onClick,
  careerIndex,
  groupIndex,
  isActive,
}: Props) => {
  const clickHandler = () => {
    if (!isActive) onClick(groupIndex, careerIndex);
  };

  return (
    <button
      className={cn(scss.container, { [scss.active]: isActive })}
      onClick={clickHandler}
    >
      <span className={scss.titleWrapper}>
        <span className={scss.title}>{title}</span>
        <span className={scss.industryTitle}>{industryTitle}</span>
      </span>
      <span className={scss.percent}>{`${percentage}%`}</span>
    </button>
  );
};

export default CareerButton;
