import React, { useEffect } from "react";
import { AssessmentStepDto } from "../../../../api/data-contracts";
import SelectIndustriesQuestion from "./components/SelectIndustriesQuestion/SelectIndustriesQuestion";
import SelectIndustriesRankQuestion from "./components/SelectIndustriesRankQuestion/SelectIndustriesRankQuestion";
import SelectJobGroupsQuestion from "./components/SelectJobGroupsQuestion/SelectJobGroupsQuestion";
import SelectPersonalityQuestion from "./components/SelectPersonalityQuestion/SelectPersonalityQuestion";
import SelectValuesQuestion from "./components/SelectValuesQuestion/SelectValuesQuestion";

type Props = {
  step: AssessmentStepDto;
  currentStepIndex: number;
};

const QuestionSection = ({ step, currentStepIndex }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step.id]);

  switch (step?.category) {
    case "INDUSTRY_SELECT_INDUSTRIES":
      return (
        <SelectIndustriesQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );

    case "INDUSTRY_SELECT_RANK":
      return (
        <SelectIndustriesRankQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );
    case "INDUSTRY_SELECT_JOB_GROUPS":
      return (
        <SelectJobGroupsQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );
    case "PERSONALITY":
      return (
        <SelectPersonalityQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );
    case "VALUES":
      return (
        <SelectValuesQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );
    case "SUBJECTS":
      return (
        <SelectValuesQuestion
          key={step.id}
          step={step}
          currentStepIndex={currentStepIndex}
        />
      );
    default:
      return null;
  }
};

export default QuestionSection;
