import { authAxiosClient, axiosClient } from "./apiClient";
import { AxiosPromise } from "axios";
import {
  AssessmentResDto,
  CareerMatchesCardsResDto,
  CareerMatchResultResDto,
  ChangePasswordReqDto,
  ChangePasswordResDto,
  CheckPaypalOrderStatusReqDto,
  CheckPaypalOrderStatusResDto,
  CheckPromoCodeReqDto,
  CheckPromoCodeResDto,
  CheckResetPasswordTokenReqDto,
  CreateCareerMatchResDto,
  CreatePaymentReqDto,
  CreatePaymentResDto,
  ForgotPasswordReqDto,
  GetStripePaymentModeResDto,
  HomePageResDto,
  LoginReqDto,
  LoginResDto,
  RegisterReqDto,
  RegisterResDto,
  ResetPasswordReqDto,
  ResetPasswordResDto,
  UpdateAssessmentReqDto,
  UpdateAssessmentResDto,
  UserDto,
} from "./data-contracts";

export const fetchHomePage = async (): Promise<HomePageResDto> => {
  return axiosClient.get(`/server/api/app/home`).then(({ data }) => data);
};

export const fetchAssessment = async (
  id: string,
): Promise<AssessmentResDto> => {
  return axiosClient
    .get(`/server/api/assessment/${id}`)
    .then(({ data }) => data);
};

export const fetchCareerMatch = async (
  id: string,
): Promise<CareerMatchResultResDto> => {
  return axiosClient
    .get(`/server/api/career-match/${id}`)
    .then(({ data }) => data);
};

export const login = async (
  body: LoginReqDto,
): Promise<AxiosPromise<LoginResDto>> => {
  return authAxiosClient.post("/server/api/user/login", body);
};

export const register = async (
  body: RegisterReqDto,
): Promise<AxiosPromise<RegisterResDto>> => {
  return authAxiosClient.post("/server/api/user/register", body);
};

export const forgotPassword = async (body: ForgotPasswordReqDto) => {
  return authAxiosClient.post("/server/api/auth/forgot-password", body);
};

export const resetPassword = async (
  body: ResetPasswordReqDto,
): Promise<AxiosPromise<ResetPasswordResDto>> => {
  return authAxiosClient.post("/server/api/auth/reset-password", body);
};

export const checkResetPasswordToken = async (
  body: CheckResetPasswordTokenReqDto,
): Promise<AxiosPromise<ResetPasswordResDto>> => {
  return authAxiosClient.post(
    "/server/api/user/check-reset-password-token",
    body,
  );
};

export const changePassword = async (
  body: ChangePasswordReqDto,
): Promise<AxiosPromise<ChangePasswordResDto>> => {
  return axiosClient.post("/server/api/auth/change-password", body);
};

export const fetchMe = async (): Promise<AxiosPromise<{ user: UserDto }>> => {
  return axiosClient.get("/server/api/user/fetch-me");
};

export const createOrGetActiveCareerMatch = async (): Promise<
  AxiosPromise<CreateCareerMatchResDto>
> => {
  return axiosClient.post("/server/api/career-matches");
};

export const createPayment = async (
  body: CreatePaymentReqDto,
): Promise<AxiosPromise<CreatePaymentResDto>> => {
  return axiosClient.post("/server/api/payments", body);
};

export const checkPaypalOrderStatus = async (
  body: CheckPaypalOrderStatusReqDto,
): Promise<AxiosPromise<CheckPaypalOrderStatusResDto>> => {
  return axiosClient.post("server/api/paypal-order-status", body);
};

export const updateAssessmentById = async (
  body: UpdateAssessmentReqDto,
): Promise<AxiosPromise<UpdateAssessmentResDto>> => {
  return axiosClient.put("/server/api/user-assessment/update", body);
};

export const fetchCareerMatches =
  async (): Promise<CareerMatchesCardsResDto> => {
    return axiosClient
      .get("server/api/career-matches")
      .then(({ data }) => data);
  };

export const getStripePaymentMode = async (): Promise<
  AxiosPromise<GetStripePaymentModeResDto>
> => {
  return axiosClient.get("/server/api/get-stripe-payment-mode");
};

export const checkPromoCode = async (
  body: CheckPromoCodeReqDto,
): Promise<AxiosPromise<CheckPromoCodeResDto>> => {
  return axiosClient.post("server/api/check-promo-code", body);
};
