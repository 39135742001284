import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";
import scss from "./styles.module.scss";

type Props = {
  id: string;
  onEnd: () => void;
  isActive: boolean;
};

const YouTubeVideoBlock = ({ id, onEnd, isActive }: Props) => {
  const youtubePlayerRef = useRef<YouTube | null>(null);

  useEffect(() => {
    if (!isActive) youtubePlayerRef.current?.internalPlayer.pauseVideo();
  }, [isActive]);

  const opts = {
    width: "100%",
    // height: 576,
    playerVars: {
      rel: 0,
    },
  };

  return (
    <YouTube
      id={id}
      ref={(ref) => (youtubePlayerRef.current = ref)}
      iframeClassName={scss.iframe}
      loading="lazy"
      opts={opts}
      videoId={id}
      onEnd={onEnd}
    />
  );
};

export default YouTubeVideoBlock;
