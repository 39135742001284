import { memo, SVGProps } from "react";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 13L13 10M13 10L10 7M13 10H2M2 5.24802V5.2002C2 4.08009 2 3.51962 2.21799 3.0918C2.40973 2.71547 2.71547 2.40973 3.0918 2.21799C3.51962 2 4.08009 2 5.2002 2H14.8002C15.9203 2 16.4796 2 16.9074 2.21799C17.2837 2.40973 17.5905 2.71547 17.7822 3.0918C18 3.5192 18 4.07899 18 5.19691V14.8036C18 15.9215 18 16.4805 17.7822 16.9079C17.5905 17.2842 17.2837 17.5905 16.9074 17.7822C16.48 18 15.921 18 14.8031 18H5.19691C4.07899 18 3.5192 18 3.0918 17.7822C2.71547 17.5905 2.40973 17.2839 2.21799 16.9076C2 16.4798 2 15.9201 2 14.8V14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(LogoutIcon);
