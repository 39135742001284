import React from "react";
import { AccountStepState } from "../../AccountModal";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import CompletedBigIcon from "../../../../icons/CompletedBigIcon";
import Button from "../../../shared/Button/Button";
import { useAppState } from "../../../../AppState";
import scss from "./styles.module.scss";

type Props = {
  step: AccountStepState;
};

const SuccessMessage = ({ step }: Props) => {
  const {
    store: { modalStore },
  } = useAppState();
  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title={
        step.prevStep === "REGISTRATION"
          ? "registration completed"
          : "password changed"
      }
      subtitle={
        step.prevStep === "REGISTRATION"
          ? "You have successfully registered."
          : "Your password has been successfully changed"
      }
    >
      <CompletedBigIcon className={scss.completeIcon} />
      <Button
        className={scss.button}
        variant="filled-primary"
        uppercase
        onClick={modalStore.closeModal}
      >
        Go to home
      </Button>
    </AccountModalInnerLayout>
  );
};

export default SuccessMessage;
