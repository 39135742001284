import React from "react";
import GuideCardItem, {
  GuideCardProps,
} from "./components/GuideCardItem/GuideCardItem";
import { PriceDto } from "../../../../api/data-contracts";
import cn from "classnames";
import scss from "./styles.module.scss";
import QuickStartModal from "../../../../components/QuickStartModal/QuickStartModal";
import { useAppState } from "../../../../AppState";

const guideCards: Array<GuideCardProps> = [
  {
    title: "Register on the <br/> <b>Guidenar</b> platform",
    description: "It only takes a few minutes",
    button: {
      title: "Get started",
    },
  },
  {
    title: "Pay with a",
    description: "Personal discount is already waiting for you",
    price: {
      oldPrice: "$",
      currentPrice: "$",
    },
  },
  {
    title: "Take the <br/> assessment",
    description: "Built on a 4-component evaluation system",
  },
  {
    title: "Get personal <br/> career guidance",
    description:
      "Platform will prepare the most suitable careers, jobs & skills",
  },
];

const AssessmentGuideSection = ({ oldPrice, currentPrice }: PriceDto) => {
  const {
    store: { modalStore },
  } = useAppState();

  const discountPercentage = ((oldPrice - currentPrice) / oldPrice) * 100;

  const openQuickStartModal = () => {
    modalStore.openModal(<QuickStartModal />, { modalClassName: scss.modal });
  };

  return (
    <div className={scss.container}>
      <h2 className={scss.title}>How it works</h2>
      <p className={scss.description}>
        Guidenar's AI-powered career assessment helps you find careers that
        align with your unique personality, values, interests, and academic
        strengths.
      </p>
      <div className={scss.guideCardsWrapper}>
        <div className={cn(scss.guideCardsInnerWrapper, "hiddenScroll")}>
          {guideCards.map((card, i) => (
            <GuideCardItem
              key={i}
              card={
                card.price
                  ? {
                      title:
                        card.title +
                        `<br/><b>${discountPercentage.toFixed()}% discount</b>`,
                      description: card.description,
                      price: {
                        oldPrice: `$${oldPrice}`,
                        currentPrice: `$${currentPrice}`,
                      },
                    }
                  : card.button
                  ? {
                      ...card,
                      button: {
                        ...card.button,
                        onClick: openQuickStartModal,
                      },
                    }
                  : card
              }
              stepNumber={i + 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssessmentGuideSection;
