import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  isAuth: boolean;
  children: ReactNode;
};

const ProtectedRoute: FC<Props> = ({ isAuth, children }) => {
  return isAuth ? <>{children}</> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
