import React, { useState } from "react";
import Dropdown from "../../../../../../components/shared/Dropdown/Dropdown";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  salaries: Record<string, string>;
  className?: string;
};

const CareerSalaries = ({ salaries, className }: Props) => {
  const [activeCountry, setActiveCountry] = useState("USA");

  const countries = Object.keys(salaries || {})
    .filter((el) => !!salaries[el])
    .sort();

  return (
    <div className={cn(scss.container, className)}>
      <div className={scss.priceWrapper}>
        <div className={scss.priceValue}>{salaries[activeCountry]}</div>
        <div className={scss.PriceText}>avg. salary</div>
      </div>
      <Dropdown
        options={countries}
        activeOption={activeCountry}
        setActiveOption={setActiveCountry}
      />
    </div>
  );
};

export default CareerSalaries;
