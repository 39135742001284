import React from "react";
import cn from "classnames";
import BenefitItem, { BenefitProps } from "./BenefitItem/BenefitItem";
import scss from "./styles.module.scss";

type Props = {
  className?: string;
  benefits: BenefitProps[];
};

const Benefits = ({ className, benefits }: Props) => {
  return (
    <div className={cn(scss.container, className)}>
      <h3 className={scss.title}>benefits of our assessment</h3>
      <div className={cn(scss.benefitsWrapper, "hiddenScroll")}>
        {benefits.map((item, i) => (
          <BenefitItem
            title={item.title}
            description={item.description}
            imageSrc={item.imageSrc}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default Benefits;
