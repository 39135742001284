import React, { useRef, useState } from "react";
import Caption from "../../../../components/shared/Caption/Caption";
import CardsWithCaptions from "./components/CardsWithCaptions/CardsWithCaptions";
import Card, { CardProps } from "../../../../components/shared/Card/Card";
import Benefits from "./components/Benefits/Benefits";
import { BenefitProps } from "./components/Benefits/BenefitItem/BenefitItem";
import { observer } from "mobx-react-lite";
import Button from "../../../../components/shared/Button/Button";
import { useAppState } from "../../../../AppState";
import AccountModal from "../../../../components/AccountModal/AccountModal";
import QuickStartModal from "../../../../components/QuickStartModal/QuickStartModal";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import cn from "classnames";
import ArrowShort from "../../../../icons/ArrowShort";
import YouTubeVideoBlock from "../../../../components/YouTubeVideoBlock/YouTubeVideoBlock";
import YouTubeIcon from "../../../../icons/YouTubeIcon";
import VideoModal from "../../../../components/VideoModal/VideoModal";
import scss from "./styles.module.scss";

const benefits: Array<BenefitProps> = [
  {
    title: "7 Assessment Criteria",
    description:
      "We have created the most effective criteria for assessing professional suitability, which helps to reduce the" +
      " time of passing the test.",
    imageSrc: "/assets/Target.webp",
  },
  {
    title: "4 Group of Questions",
    description:
      "Your interests, personality type, values, and favorite subjects shape your predispositions to careers where you can best realize yourself.",
    imageSrc: "/assets/Puzzle.webp",
  },
  {
    title: "Detailed Report",
    description:
      "Receive complete information about which career suits you best, as well as an expanded list of jobs with their features.",
    imageSrc: "/assets/Megaphone.webp",
  },
];

type Props = {
  videos: string[];
};

const MainSection = ({ videos }: Props) => {
  const {
    store: { resolutionStore, userStore, modalStore },
  } = useAppState();
  const { tablet, phone, phoneLg } = resolutionStore.resolution;
  const isMobile = tablet || phoneLg || phone;
  const isAuth = userStore.isAuth;
  const swiperRef = useRef<SwiperRef | null>(null);

  const [activeVideoIndex, setActiveVideoIndex] = useState(0);

  const openModalHandler = () => {
    modalStore.openModal(<AccountModal startStep={"LOGIN"} />, {
      modalClassName: scss.modal,
    });
  };

  const getStartedHandler = async () => {
    modalStore.openModal(<QuickStartModal />, { modalClassName: scss.modal });
  };

  const openVideoModal = () => {
    modalStore.openModal(
      <VideoModal videos={videos} activeIndex={activeVideoIndex} />,
      { modalClassName: scss.videoModal },
    );
  };

  const cards: Array<CardProps> = [
    userStore.user.activeAssessmentId
      ? {
          caption: "Assessment in Progress",
          title: "Continuing the Assessment",
          actionTitle: "Resume right now",
          onClick: getStartedHandler,
          variant: "green",
        }
      : {
          caption: "Ready to start?",
          title: "Begin the Assessment",
          actionTitle: "Get started now",
          onClick: getStartedHandler,
          variant: "green",
        },
    {
      caption: "Assessed before?",
      title: "Sign In to your Account",
      actionTitle: "Sign In",
      onClick: openModalHandler,
      variant: "orange",
    },
  ];

  const prevNextHandler = (direction: "prev" | "next") => {
    if (direction === "next") {
      setActiveVideoIndex((prevState) =>
        prevState === videos.length - 1 ? 0 : prevState + 1,
      );
    } else {
      setActiveVideoIndex((prevState) =>
        prevState === 0 ? videos.length - 1 : prevState - 1,
      );
    }
  };

  const swiperNextSlide = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const swiperPrevSlide = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  return (
    <div
      className={scss.wrapper}
      style={{ backgroundImage: `url(/assets/home-page-bg.webp)` }}
    >
      <div className={scss.innerWrapper}>
        <div className={scss.caption}>
          <Caption title="THE QUICKEST WAY TO FIND YOUR CAREER MATCH" />
        </div>
        <h1 className={scss.title}>
          Don't know where to start or what profession to choose?
        </h1>
        {videos.length ? (
          <div className={scss.swiperWrapper}>
            <Button
              className={cn(scss.button, scss.prevButton)}
              onClick={swiperPrevSlide}
            >
              <ArrowShort />
            </Button>
            <Button className={scss.button} onClick={swiperNextSlide}>
              <ArrowShort />
            </Button>
            <Swiper
              onSlideNextTransitionEnd={() => prevNextHandler("next")}
              onSlidePrevTransitionEnd={() => prevNextHandler("prev")}
              ref={swiperRef}
              initialSlide={activeVideoIndex}
              slidesPerView={1}
              spaceBetween={isMobile ? 24 : 72}
              loop={true}
              className={cn(scss.swiper)}
            >
              {videos.map((item, i) => (
                <SwiperSlide key={item+i}>
                  {isMobile ? (
                    <div
                      onClick={openVideoModal}
                      style={{
                        backgroundImage: `url(https://i.ytimg.com/vi_webp/${item}/hqdefault.webp)`,
                      }}
                      className={scss.videoImageWrapper}
                    >
                      <YouTubeIcon
                        width={64}
                        style={{ position: "relative", zIndex: 3 }}
                      />
                    </div>
                  ) : (
                    <YouTubeVideoBlock
                      id={item}
                      isActive={i === activeVideoIndex}
                      onEnd={swiperNextSlide}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <p className={scss.description}>
            Based on our extensive research, we have developed a unique career
            assessment that helps you identify careers that align with your true
            calling.
          </p>
        )}
        <div className={scss.cardsWrapper}>
          {cards.map((card, i) =>
            isAuth && i ? null : isMobile ? (
              <Button
                className={scss.cardButton}
                variant={!i ? "filled-primary" : "filled-secondary"}
                uppercase
                onClick={card.onClick}
                key={i}
              >
                {card.actionTitle}
              </Button>
            ) : (
              <Card
                className={scss.card}
                key={i}
                title={card.title}
                caption={card.caption}
                actionTitle={card.actionTitle}
                variant={card.variant}
                onClick={card.onClick}
              />
            ),
          )}
        </div>
        <div className={scss.cardsWithCaptions}>
          <CardsWithCaptions />
        </div>
      </div>
      <Benefits benefits={benefits} />
    </div>
  );
};

export default observer(MainSection);
