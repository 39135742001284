import React, { useCallback, useEffect, useState } from "react";
import QuickStartModalInnerLayout from "../QuickStartModalInnerLayout/QuickStartModalInnerLayout";
import {
  CareerMatchDto,
  PaymentMethodDto,
} from "../../../../api/data-contracts";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../../AppState";
import PaymentMethod from "./components/PaymentMethod/PaymentMethod";
import Button from "../../../shared/Button/Button";
import { QuickStartModalSteps } from "../../QuickStartModal";
import { loadStripe } from "@stripe/stripe-js";
import Preloader from "../../../../icons/Preloader";
import scss from "./styles.module.scss";
import { toast } from "react-toastify";
import Input from "../../../shared/Input/Input";
import cn from "classnames";

type Props = {
  activeCareerMatch: CareerMatchDto;
  setStep: (step: QuickStartModalSteps) => void;
  isPending?: boolean;
};

type PromoCodeStateProps = {
  discount: number;
  promoCodeRes: string;
  error: string;
  promoCode: string;
};

const PaymentForm = ({ activeCareerMatch, setStep, isPending }: Props) => {
  const {
    service: { assessmentService },
    store: { pageStore },
  } = useAppState();
  const price = pageStore.price;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodDto>();
  const [submitting, setSubmitting] = useState(false);
  const [pending, setPending] = useState(isPending);
  const [promoCodeState, setPromoCodeState] = useState<PromoCodeStateProps>({
    promoCodeRes: "",
    error: "",
    discount: 0,
    promoCode: "",
  });

  const priceFree = promoCodeState.discount === 100;

  const checkPaymentStatus = useCallback(
    async (data: any) => {
      const liveMode = await assessmentService.getStripePaymentMode();

      const stripePromise = loadStripe(
        liveMode
          ? (process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE as string)
          : (process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST as string),
      );

      const stripe = await stripePromise;

      if (data?.paymentStatus === "PAID") {
        setStep("PAYMENT_SUCCESS");
        setPending(false);
      } else if (data?.paymentStatus === "PENDING") {
        setPending(true);
      } else {
        setPending(false);
      }

      if (data?.sessionId) {
        await stripe?.redirectToCheckout({ sessionId: data.sessionId });
      }

      if (data?.paypalApprovalUrl) {
        window.location.href = data.paypalApprovalUrl;
      }

      if (data?.error) {
        toast(data.error, { type: "error", position: "bottom-right" });
      }
    },
    [assessmentService, setStep],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (pending) {
      intervalId = setInterval(async () => {
        const data = await assessmentService.createPayment({
          careerMatchId: activeCareerMatch.id,
          paymentMethod: paymentMethod!,
        });
        await checkPaymentStatus(data);
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    pending,
    paymentMethod,
    activeCareerMatch.id,
    assessmentService,
    checkPaymentStatus,
  ]);

  const changeMethod = (method: PaymentMethodDto) => () => {
    setPaymentMethod(method);
  };

  const submit = async () => {
    setSubmitting(true);
    const data = await assessmentService.createPayment(
      priceFree
        ? {
            code: promoCodeState.promoCodeRes,
            careerMatchId: activeCareerMatch.id,
          }
        : promoCodeState.promoCodeRes
          ? {
              careerMatchId: activeCareerMatch.id,
              paymentMethod: paymentMethod as PaymentMethodDto,
              code: promoCodeState.promoCodeRes,
            }
          : {
              careerMatchId: activeCareerMatch.id,
              paymentMethod: paymentMethod as PaymentMethodDto,
            },
    );
    await checkPaymentStatus(data);
    setSubmitting(false);
  };

  const checkPromoCode = async () => {
    setSubmitting(true);
    const { discount, errorMessage, code } =
      await assessmentService.checkPromoCode({
        code: promoCodeState.promoCode,
      });

    setPromoCodeState((prevState) =>
      errorMessage
        ? { ...prevState, error: errorMessage }
        : {
            ...prevState,
            promoCodeRes: code as string,
            discount: discount as number,
          },
    );

    setSubmitting(false);
  };

  const onChangeHandler = (code: string) => {
    setPromoCodeState((prevState) => ({
      ...prevState,
      error: "",
      promoCode: code,
    }));
  };

  const priceWithPromoCode = (
    price.currentPrice -
    price.currentPrice * (promoCodeState.discount / 100)
  ).toFixed(2);

  return (
    <QuickStartModalInnerLayout
      innerClassName={scss.container}
      title={pending ? "Processing your payment" : "choose a payment method"}
      subtitle={
        pending
          ? "Your payment is currently being processed. Please wait a moment as we securely handle your transaction."
          : "Select your preferred payment method to seamlessly complete your transaction and" +
            " unlock a world of" +
            " possibilities."
      }
      progress={{ maxSteps: 2, currentStep: 2 }}
    >
      {pending ? (
        <div className={scss.preloader}>
          <Preloader />
        </div>
      ) : (
        <>
          <div className={scss.priceBlockWrapper}>
            <div className={scss.priceTitle}>Price</div>
            <div className={scss.priceWrapper}>
              <div className={scss.currentPriceWrapper}>
                <div
                  className={cn(scss.currentPrice, {
                    [scss.nonActual]: promoCodeState.discount,
                  })}
                >
                  ${price.currentPrice}
                </div>
                <div className={scss.discountResultWrapper}>
                  <div
                    className={cn(scss.discountResultInnerWrapper, {
                      [scss.visible]: promoCodeState.discount,
                    })}
                  >
                    <div className={scss.discountValue}>
                      -{promoCodeState.discount}%
                    </div>
                    <div>${priceWithPromoCode}</div>
                  </div>
                </div>
              </div>
              <div className={scss.oldPrice}>${price.oldPrice}</div>
            </div>
          </div>
          <div className={scss.paymentMethodsWrapper}>
            <PaymentMethod
              disabled={priceFree}
              isActive={paymentMethod === "CARD"}
              paymentMethod="CARD"
              onClick={changeMethod("CARD")}
            />
            <PaymentMethod
              disabled={priceFree}
              isActive={paymentMethod === "PAY_PAL"}
              paymentMethod="PAY_PAL"
              onClick={changeMethod("PAY_PAL")}
            />
            <div className={scss.promoCodeWrapper}>
              <Input
                disabled={!!promoCodeState.promoCodeRes}
                placeholder="Enter promo code"
                value={promoCodeState.promoCode}
                onChange={onChangeHandler}
                type="text"
                name="promoCode"
                errorText={promoCodeState.error}
                fullWidth
                className={scss.input}
              />
              <Button
                disabled={!!promoCodeState.promoCodeRes}
                variant="filled-primary"
                onClick={checkPromoCode}
                className={scss.checkPromoCodeButton}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
      <Button
        disabled={(!priceFree && !paymentMethod) || pending}
        loading={submitting}
        variant="filled-primary"
        uppercase
        onClick={submit}
      >
        {pending ? "Processing..." : "Submit"}
      </Button>
      <p className={scss.agreementText}>
        By clicking ‘Submit’, you agree to the{" "}
        <a
          href="https://guidenar.com/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
        .
      </p>
    </QuickStartModalInnerLayout>
  );
};

export default observer(PaymentForm);
