import React from "react";
import { CareerMainInfoDto } from "../../../../api/data-contracts";
import { getPercentValue } from "../../../../utils/numberUtils";
import scss from "./styles.module.scss";

type Props = {
  career: CareerMainInfoDto;
};

const CareerItem = ({ career }: Props) => {
  const percent = `${getPercentValue(
    career.careerPoints.totalPoints,
    career.careerPoints.maxPoints,
  )}%`;

  return (
    <div className={scss.careerWrapper}>
      <div className={scss.title}>{career.title}</div>
      <div className={scss.percent}>{percent}</div>
    </div>
  );
};

export default CareerItem;
