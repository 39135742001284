import { memo, SVGProps } from "react";

const PaymentIconPayPal = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="16"
      viewBox="0 0 62 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1331_1472)">
        <path
          d="M19.8257 3.19965C19.6981 3.19965 19.5897 3.28982 19.5698 3.4126L18.0927 12.5304C18.0874 12.5637 18.0895 12.5977 18.0989 12.6302C18.1084 12.6626 18.1249 12.6927 18.1474 12.7183C18.1699 12.7439 18.1979 12.7645 18.2293 12.7786C18.2608 12.7927 18.295 12.8 18.3296 12.8H20.0813C20.2087 12.8 20.3172 12.7096 20.3372 12.587L20.7711 9.90953C20.7908 9.78693 20.8994 9.69658 21.027 9.69658H22.6195C24.4803 9.69658 26.0598 8.37502 26.3483 6.58426C26.6392 4.77835 25.1898 3.20357 23.1356 3.19965H19.8257ZM21.5284 5.23508H22.8026C23.8516 5.23508 24.1932 5.83759 24.0852 6.50389C23.9772 7.17162 23.4466 7.66346 22.4319 7.66346H21.135L21.5284 5.23508ZM29.9109 5.85487C29.4686 5.85648 28.9596 5.94469 28.3883 6.17635C27.0777 6.70811 26.4483 7.80709 26.181 8.60829C26.181 8.60829 25.3303 11.0527 27.2523 12.3956C27.2523 12.3956 29.0349 13.6885 31.0415 12.316L31.0069 12.5304C31.0015 12.5636 31.0037 12.5977 31.0131 12.6301C31.0225 12.6625 31.039 12.6925 31.0615 12.7181C31.0839 12.7438 31.1118 12.7643 31.1432 12.7785C31.1747 12.7926 31.2088 12.7999 31.2434 12.8H32.9063C33.0339 12.8 33.1423 12.7096 33.1622 12.587L34.1737 6.34315C34.1791 6.30985 34.177 6.2758 34.1677 6.24334C34.1583 6.21089 34.1418 6.18079 34.1193 6.15514C34.0968 6.12948 34.0689 6.10887 34.0374 6.09472C34.006 6.08057 33.9718 6.07322 33.9371 6.07317H32.2743C32.1467 6.07317 32.0382 6.16352 32.0184 6.28613L31.964 6.62222C31.964 6.62222 31.2379 5.85006 29.9109 5.85487ZM29.9652 7.82705C30.1562 7.82705 30.3308 7.85271 30.4875 7.90172C31.205 8.1259 31.6118 8.79683 31.4941 9.52408C31.3489 10.4195 30.5945 11.0789 29.6269 11.0789C29.4362 11.0789 29.2615 11.0534 29.1046 11.0044C28.3874 10.7802 27.978 10.1093 28.0957 9.38205C28.2409 8.48658 28.9977 7.82705 29.9652 7.82705Z"
          fill="#003087"
        />
        <path
          d="M43.771 3.19965C43.6434 3.19965 43.535 3.28982 43.5151 3.4126L42.0381 12.5304C42.0327 12.5637 42.0348 12.5977 42.0442 12.6302C42.0537 12.6626 42.0702 12.6927 42.0927 12.7183C42.1152 12.7439 42.1432 12.7645 42.1746 12.7786C42.2061 12.7927 42.2403 12.8 42.2749 12.8H44.0266C44.154 12.8 44.2625 12.7096 44.2825 12.587L44.7164 9.90953C44.7361 9.78693 44.8447 9.69658 44.9723 9.69658H46.5648C48.4256 9.69658 50.0049 8.37502 50.2933 6.58426C50.5843 4.77835 49.1352 3.20357 47.0809 3.19965H43.771ZM45.4737 5.23508H46.7479C47.7969 5.23508 48.1385 5.83759 48.0305 6.50389C47.9225 7.17162 47.3921 7.66346 46.3772 7.66346H45.0803L45.4737 5.23508ZM53.856 5.85487C53.4137 5.85648 52.9048 5.94469 52.3334 6.17635C51.0228 6.70811 50.3934 7.80709 50.1261 8.60829C50.1261 8.60829 49.2758 11.0527 51.1978 12.3956C51.1978 12.3956 52.98 13.6885 54.9868 12.316L54.9522 12.5304C54.9469 12.5637 54.949 12.5977 54.9584 12.6302C54.9678 12.6626 54.9844 12.6927 55.0069 12.7183C55.0294 12.7439 55.0574 12.7645 55.0888 12.7786C55.1203 12.7927 55.1545 12.8 55.1891 12.8H56.8518C56.9794 12.8 57.0878 12.7096 57.1077 12.587L58.1195 6.34315C58.125 6.30982 58.1229 6.27574 58.1135 6.24326C58.1041 6.21077 58.0875 6.18067 58.065 6.155C58.0425 6.12934 58.0145 6.10874 57.9831 6.09461C57.9516 6.08048 57.9173 6.07317 57.8826 6.07317H56.2198C56.0922 6.07317 55.9836 6.16352 55.9639 6.28613L55.9095 6.62222C55.9095 6.62222 55.1831 5.85006 53.856 5.85487ZM53.9104 7.82705C54.1013 7.82705 54.276 7.85271 54.4327 7.90172C55.1501 8.1259 55.5569 8.79683 55.4392 9.52408C55.294 10.4195 54.5396 11.0789 53.5721 11.0789C53.3813 11.0789 53.2067 11.0534 53.0498 11.0044C52.3325 10.7802 51.9231 10.1093 52.0409 9.38205C52.186 8.48658 52.9428 7.82705 53.9104 7.82705Z"
          fill="#0070E0"
        />
        <path
          d="M34.9623 6.07318C34.8305 6.07318 34.7367 6.19845 34.777 6.32052L36.6 11.8272L34.9517 14.4222C34.8717 14.5482 34.9647 14.7109 35.1168 14.7109H37.065C37.1207 14.7108 37.1755 14.6968 37.224 14.6701C37.2725 14.6435 37.3132 14.6051 37.342 14.5587L42.4334 6.36008C42.5116 6.23409 42.4182 6.073 42.267 6.073H40.3189C40.2627 6.07304 40.2074 6.08735 40.1585 6.1145C40.1097 6.14165 40.0689 6.18072 40.0403 6.22786L38.0362 9.52141L37.0189 6.25227C36.9858 6.14588 36.8849 6.073 36.7708 6.073L34.9623 6.07318Z"
          fill="#003087"
        />
        <path
          d="M60.0097 3.19965C59.8823 3.19965 59.7737 3.28999 59.7538 3.4126L58.2764 12.53C58.271 12.5633 58.273 12.5974 58.2824 12.6299C58.2919 12.6624 58.3084 12.6925 58.3309 12.7182C58.3534 12.7438 58.3814 12.7644 58.4129 12.7786C58.4444 12.7927 58.4786 12.8 58.5133 12.8H60.2649C60.3923 12.8 60.5009 12.7096 60.5208 12.587L61.9979 3.46927C62.0032 3.43599 62.0011 3.40198 61.9917 3.36957C61.9823 3.33716 61.9658 3.30711 61.9433 3.2815C61.9208 3.25588 61.8929 3.2353 61.8615 3.22117C61.8301 3.20705 61.7959 3.1997 61.7613 3.19965H60.0097Z"
          fill="#0070E0"
        />
        <path
          d="M5.22199 3.19965C5.10915 3.19974 5.00005 3.23899 4.91429 3.31036C4.82852 3.38173 4.7717 3.48054 4.75405 3.58902L3.97656 8.3891C4.01281 8.16492 4.21125 7.99973 4.44449 7.99973H6.7228C9.01576 7.99973 10.9614 6.37149 11.317 4.16497C11.3434 4.00024 11.3584 3.83395 11.3618 3.66725C10.7791 3.36983 10.0946 3.19965 9.34455 3.19965H5.22199Z"
          fill="#001C64"
        />
        <path
          d="M11.3633 3.66743C11.3599 3.83414 11.3449 4.00042 11.3184 4.16516C10.9629 6.37167 9.01703 7.99991 6.72425 7.99991H4.44593C4.21288 7.99991 4.01426 8.16493 3.97801 8.38929L3.26311 12.8002L2.81533 15.5673C2.80659 15.6208 2.80986 15.6754 2.82493 15.7276C2.84001 15.7797 2.86652 15.828 2.90264 15.8691C2.93875 15.9103 2.98361 15.9434 3.03412 15.966C3.08463 15.9887 3.13959 16.0004 3.1952 16.0003H5.66812C5.78095 16.0003 5.89005 15.961 5.97582 15.8896C6.06159 15.8183 6.1184 15.7195 6.13604 15.611L6.7874 11.5895C6.80506 11.4809 6.86192 11.3821 6.94777 11.3107C7.03361 11.2393 7.14281 11.2001 7.25571 11.2001H8.71167C11.0046 11.2001 12.9503 9.57184 13.3058 7.36533C13.5583 5.79928 12.748 4.37419 11.3633 3.66743Z"
          fill="#0070E0"
        />
        <path
          d="M2.41325 0.000228882C2.18021 0.000228882 1.98158 0.165245 1.94533 0.389247L0.00477599 12.3674C-0.0320212 12.5947 0.148491 12.8004 0.385019 12.8004H3.26271L3.97723 8.3895L4.75473 3.58942C4.77237 3.48094 4.82919 3.38213 4.91496 3.31076C5.00072 3.23939 5.10982 3.20014 5.22266 3.20005H9.34523C10.0954 3.20005 10.7798 3.37041 11.3625 3.66765C11.4024 1.65877 9.69947 0.000228882 7.35817 0.000228882H2.41325Z"
          fill="#003087"
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_1472">
          <rect width="62" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(PaymentIconPayPal);
