import React, { ReactNode, useEffect, useRef } from "react";
import cn from "classnames";
import Caption from "../../../../../../components/shared/Caption/Caption";
import scss from "./styles.module.scss";

type Props = {
  caption?: string;
  title: string;
  subtitle?: ReactNode;
  className?: string;
  innerClassName?: string;
  children: ReactNode;
  withSeparator?: boolean;
};

const QuestionSectionLayout = ({
  caption,
  title,
  subtitle,
  className,
  innerClassName,
  children,
  withSeparator,
}: Props) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      ref.current?.classList.add(scss.show);
      mount.current = true;
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={cn(
        scss.container,
        {
          [scss.show]: mount.current,
        },
        className,
      )}
    >
      <div className={scss.titleWrapper}>
        <div className={scss.titleInnerWrapper}>
          {caption && <Caption title={caption} paddings="m" />}
          <h3 className={scss.title}>{title}</h3>
        </div>
        {subtitle}
      </div>
      {withSeparator && <div className={scss.separator} />}
      <div className={scss.innerContainer}>
        <div className={cn(scss.innerWrapper, innerClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default QuestionSectionLayout;
