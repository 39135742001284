import React, { ReactNode } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import PreloaderSmall from "../../../icons/PreloaderSmall";
import scss from "./styles.module.scss";

type IProps = {
  className?: string;
  children: ReactNode;
  variant:
    | "filled-primary"
    | "filled-secondary"
    | "filled-white"
    | "outline"
    | "outfilled";
  size: "small" | "medium" | "large";
  roundBorder: "small" | "large";
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  active?: boolean;
  loading?: boolean;
  uppercase?: boolean;
} & (
  | {
      type?: "button";
      actionType?: "submit" | "reset" | "button" | undefined;
    }
  | {
      type: "link";
      href: string;
    }
  | {
      type: "innerLink";
      to: string;
    }
);

const Button = (props: IProps) => {
  const classname = cn(
    scss.button,
    scss[props.variant],
    scss[props.size],
    scss[`roundBorder-${props.roundBorder}`],
    {
      [scss.uppercase]: props.uppercase,
      [scss.fullWidth]: props.fullWidth,
      [scss.disabled]: props.disabled,
      [scss.active]: props.active,
      [scss.loading]: props.loading,
    },
    props.className,
  );

  const content = (
    <>
      {props.iconBefore ? (
        <div className={cn(scss.iconWrapper, scss.iconBefore)}>
          {props.iconBefore}
        </div>
      ) : null}
      {props.children}
      {props.iconAfter && (
        <div className={cn(scss.iconWrapper, scss.iconAfter)}>
          {props.iconAfter}
        </div>
      )}
      {typeof props.loading === "undefined" ? null : (
        <div
          className={cn(scss.preloaderIconWrapper, {
            [scss.show]: props.loading,
          })}
        >
          <PreloaderSmall />
        </div>
      )}
    </>
  );
  switch (props.type) {
    case "innerLink":
      return (
        <NavLink to={props.to} className={classname}>
          {content}
        </NavLink>
      );
    case "link":
      return (
        <a href={props.href} className={classname}>
          {content}
        </a>
      );
    default:
      return (
        <button
          type={props.actionType}
          disabled={props.disabled}
          onClick={
            !props.disabled && !props.loading ? props.onClick : undefined
          }
          className={classname}
        >
          {content}
        </button>
      );
  }
};

Button.defaultProps = {
  size: "large",
  variant: "outline",
  type: "button",
  roundBorder: "large",
};

export default Button;
