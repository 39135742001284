import React from "react";
import { PersonalityInfoDto } from "../../../../api/data-contracts";
import scss from "./styles.module.scss";

type Props = {
  personality: PersonalityInfoDto;
};

const PersonalityItem = ({ personality }: Props) => {
  return (
    <div className={scss.personalityWrapper}>
      <p className={scss.title}>{personality.title}</p>
      {personality.description && (
        <p className={scss.description}>{personality.description}</p>
      )}
    </div>
  );
};

export default PersonalityItem;
