import { makeAutoObservable } from "mobx";
import { PaymentDto, UserDto } from "../api/data-contracts";

export class UserStore {
  private _user: UserDto = {} as UserDto;
  private _isAuth: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUser = (user: UserDto) => {
    this._user = user;
    this._isAuth = true;
  };

  setActiveAssessmentId = (id: string) => {
    this._user.activeAssessmentId = id;
  };

  clearActiveAssessmentId = () => {
    this._user.activeAssessmentId = undefined;
  };

  clearUser = () => {
    this._user = {} as UserDto;
    this._isAuth = false;
  };

  addPayment = (payment: PaymentDto) => {
    this._user.payments = [...this._user.payments, payment];
  };

  incrementCareerMatchesCount = () => {
    this._user.careerMatchesCount++;
    const el = document.getElementById("matches-count-circle");
    if (el) {
      el.classList.add("pulse-animation");
      setTimeout(() => el.classList.remove("matches-count-circle"), 9000);
    }
  };

  get user() {
    return this._user;
  }

  get isAuth() {
    return this._isAuth;
  }
}
