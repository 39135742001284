import { memo, SVGProps } from "react";

const PasswordIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.57666 6.33333H4.80017C3.82008 6.33333 3.32967 6.33333 2.95532 6.5271C2.62604 6.69754 2.35852 6.96931 2.19074 7.30382C2 7.68411 2 8.1823 2 9.17795V14.1557C2 15.1514 2 15.6489 2.19074 16.0292C2.35852 16.3637 2.62604 16.636 2.95532 16.8064C3.3293 17 3.81914 17 4.79732 17H13.2027C14.1809 17 14.67 17 15.044 16.8064C15.3733 16.636 15.6417 16.3637 15.8094 16.0292C16 15.6493 16 15.1524 16 14.1587V9.17503C16 8.18133 16 7.68373 15.8094 7.30382C15.6417 6.96931 15.3733 6.69754 15.044 6.5271C14.6696 6.33333 14.1803 6.33333 13.2002 6.33333H11.4228M6.57666 6.33333H11.4228M6.57666 6.33333C6.46514 6.33333 6.375 6.24149 6.375 6.12821V3.66667C6.375 2.19391 7.55025 1 9 1C10.4497 1 11.625 2.19391 11.625 3.66667V6.12821C11.625 6.2415 11.5343 6.33333 11.4228 6.33333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(PasswordIcon);
