import React, { useRef } from "react";
import { AssessmentStepOptionDto } from "../../../api/data-contracts";
import { createArrayFromNumber } from "../../../utils/arrayUtils";
import cn from "classnames";
import { KeyCode } from "../../../utils/keyCode";
import scss from "./styles.module.scss";

type Props = {
  option: AssessmentStepOptionDto;
  onChange: (optionId: string, rank: number) => void;
  currentRank: number;
  maxRank?: number;
  infoCaptions?: {
    left?: string;
    right?: string;
  };
};

const RadioButtonRankSelect = ({
  currentRank = 3,
  option,
  onChange,
  maxRank = 5,
  infoCaptions,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const arr = createArrayFromNumber(maxRank);

  const handleRankChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const newRank = parseInt(event.target.value);
    onChange(option.relationId, newRank);
  };

  const onclick = () => {
    ref.current?.focus();
  };

  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (document.activeElement === ref.current) {
      if (e.keyCode === KeyCode.LEFT_ARROW) {
        if (currentRank === 1) return;
        else onChange(option.relationId, currentRank - 1);
      }
      if (e.keyCode === KeyCode.RIGHT_ARROW) {
        if (currentRank === maxRank) return;
        else onChange(option.relationId, currentRank + 1);
      }
    }
  };

  return (
    <div className={scss.container}>
      {infoCaptions && (
        <div className={cn(scss.infoCaptionsWrapper, scss.captionMobile)}>
          {infoCaptions?.left && (
            <p className={scss.caption}>{infoCaptions.left}</p>
          )}
          {infoCaptions.right && (
            <p className={scss.caption}>{infoCaptions.right}</p>
          )}
        </div>
      )}
      <div className={scss.title}>
        {infoCaptions?.left && (
          <p className={scss.caption}>{infoCaptions.left}</p>
        )}
        {option.title}
        {infoCaptions?.right && (
          <p className={scss.caption}>{infoCaptions.right}</p>
        )}
      </div>
      <div className={scss.formWrapper}>
        {infoCaptions && (
          <div className={scss.infoCaptionsWrapper}>
            {infoCaptions?.left && (
              <p className={scss.caption}>{infoCaptions.left}</p>
            )}
            {infoCaptions.right && (
              <p className={scss.caption}>{infoCaptions.right}</p>
            )}
          </div>
        )}
        <div
          ref={ref}
          className={scss.formInnerWrapper}
          tabIndex={1}
          onClick={onclick}
          onKeyUp={onKeyUp}
        >
          {arr.map((value) => (
            <label
              key={value}
              className={cn(scss.inputWrapper, {
                [scss.checked]: currentRank === value,
              })}
            >
              <div className={scss.valueWrapper}>
                <span className={scss.value}>{value}</span>
              </div>
              <input
                className={scss.radioOrigin}
                type="radio"
                value={value}
                checked={currentRank === value}
                onChange={handleRankChange}
              />
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RadioButtonRankSelect;
