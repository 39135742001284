import React, { useEffect, useState } from "react";
import Logo from "../../icons/Logo";
import Button from "../shared/Button/Button";
import cn from "classnames";
import AccountModal from "../AccountModal/AccountModal";
import { useAppState } from "../../AppState";
import { observer } from "mobx-react-lite";
import ActionButtonsWrapper from "./components/ActionButtonsWrapper/ActionButtonsWrapper";
import SettingsIcon from "../../icons/SettingsIcon";
import QuickStartModal from "../QuickStartModal/QuickStartModal";
import scss from "./styles.module.scss";

const Header = () => {
  const {
    store: { modalStore, userStore },
  } = useAppState();

  const [filled, setFilled] = useState(false);
  const isAuth = userStore.isAuth;
  const user = userStore.user;
  const isCareerMatches = !!user.careerMatchesCount;

  useEffect(() => {
    const handleScroll = () => {
      setFilled(window.scrollY > 40);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openAuthModal = () => {
    modalStore.openModal(<AccountModal startStep="LOGIN" />, {
      modalClassName: scss.modal,
    });
  };

  const openPersonal = () => {
    modalStore.openModal(<AccountModal startStep="PERSONAL" />, {
      modalClassName: scss.modal,
    });
  };

  const openQuickStartModal = () => {
    modalStore.openModal(<QuickStartModal />, { modalClassName: scss.modal });
  };

  return (
    <header className={cn(scss.wrapper, { [scss.withBackground]: filled })}>
      <div className={scss.innerWrapper}>
        <Logo />
        <Button
          className={scss.hideMobile}
          type="link"
          variant="outline"
          uppercase
          href="https://guidenar.com/"
        >
          Back to Main Site
        </Button>
        {!isAuth ? (
          <ActionButtonsWrapper key={+isAuth}>
            <Button variant="filled-white" uppercase onClick={openAuthModal}>
              sign in
            </Button>
            <Button
              variant="filled-primary"
              uppercase
              onClick={openQuickStartModal}
              className={scss.hideMobile}
            >
              Get started
            </Button>
          </ActionButtonsWrapper>
        ) : (
          <ActionButtonsWrapper key={+isAuth}>
            <Button
              type="innerLink"
              to="/career-match"
              className={scss.careerMatchesButton}
              variant={isCareerMatches ? "filled-white" : "outline"}
            >
              <p>Test Completed</p>
              <p
                id="matches-count-circle"
                className={cn(scss.careerMatchesCount, {
                  [scss.withCount]: isCareerMatches,
                })}
              >
                {user.careerMatchesCount}
              </p>
            </Button>
            <Button
              className={scss.userProfileButton}
              variant="filled-white"
              onClick={openPersonal}
            >
              <p>User profile</p>
              <div className={scss.settingsIcon}>
                <SettingsIcon />
              </div>
            </Button>
          </ActionButtonsWrapper>
        )}
      </div>
    </header>
  );
};

export default observer(Header);
