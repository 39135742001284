import { useEffect } from "react";
import { useAppState } from "../../AppState";

const throttle = require("lodash.throttle");

const ResolutionDetector = () => {
  const {
    store: { resolutionStore },
  } = useAppState();

  useEffect(() => {
    const handler = () => {
      const width = window.innerWidth;
      const res = {
        phone: false, // 360px
        phoneLg: false, // 568px
        tablet: false, // 768px
        tabletLg: false, // 992px
        desktop: false, // 1024px
        desktopLg: false, // 1200px
        desktopCLg: false, // 1320px
        desktopXlg: false, // 1440px
        laptop: false, // 1536px
        laptopLg: false, // 1600px
        laptopHd: false, // 1920px
      };
      if (width < 360) res.phone = true;
      else if (width < 568) res.phoneLg = true;
      else if (width < 768) res.tablet = true;
      else if (width < 992) res.tabletLg = true;
      else if (width < 1024) res.desktop = true;
      else if (width < 1200) res.desktopLg = true;
      else if (width < 1320) res.desktopCLg = true;
      else if (width < 1440) res.desktopXlg = true;
      else if (width < 1536) res.laptop = true;
      else if (width < 1600) res.laptopLg = true;
      else res.laptopHd = true;
      resolutionStore.setResolution(res);
    };
    handler();
    const handlerWithThrottle = throttle(handler, 100);
    window.addEventListener("resize", handlerWithThrottle, true);
    return () => {
      window.removeEventListener("resize", handlerWithThrottle, true);
    };
  }, [resolutionStore]);
  return null;
};

// const checkResolution = (width: number) => window.matchMedia(`(max-width: ${width}px), (max-device-width: ${width}px)`).matches;

export default ResolutionDetector;
