import {
  ChangePasswordReqDto,
  CheckResetPasswordTokenReqDto,
  ForgotPasswordReqDto,
  LoginReqDto,
  RegisterReqDto,
  ResetPasswordReqDto,
} from "../api/data-contracts";
import {
  changePassword,
  checkResetPasswordToken,
  forgotPassword,
  login,
  register,
  resetPassword,
} from "../api/api";
import { AppState } from "../AppState";

export class AuthService {
  private root: AppState;

  constructor(root: AppState) {
    this.root = root;
  }

  public login = async ({ password, identifier }: LoginReqDto) => {
    const { data } = await login({ identifier, password });
    this.setToken(data.jwt);
    await this.root.service.userService.fetchMe();
  };

  public register = async ({ password, email }: RegisterReqDto) => {
    const { data } = await register({ email, password });
    this.setToken(data.jwt);
    await this.root.service.userService.fetchMe();
  };

  public forgotPassword = async ({ email }: ForgotPasswordReqDto) => {
    await forgotPassword({ email });
  };

  public checkResetPasswordToken = async (
    body: CheckResetPasswordTokenReqDto,
  ) => {
    await checkResetPasswordToken(body);
  };

  public resetPassword = async ({
    code,
    password,
    passwordConfirmation,
  }: ResetPasswordReqDto) => {
    try {
      const { data } = await resetPassword({
        code,
        password,
        passwordConfirmation,
      });
      this.setToken(data.jwt);
      await this.root.service.userService.fetchMe();
    } catch (_) {}
  };

  public changePassword = async ({
    currentPassword,
    password,
    passwordConfirmation,
  }: ChangePasswordReqDto) => {
    const { data } = await changePassword({
      currentPassword,
      password,
      passwordConfirmation,
    });
    this.setToken(data.jwt);
    await this.root.service.userService.fetchMe();
  };

  public logOut = () => {
    try {
      this.removeToken();
      this.root.store.userStore.clearUser();
    } catch (e) {
      console.log(e);
    }
  };

  private setToken = (token: string) => {
    localStorage.setItem("token", token);
  };
  private removeToken = () => {
    localStorage.removeItem("token");
  };
  getToken = () => {
    return localStorage.getItem("token");
  };
}
