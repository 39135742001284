import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PageLayout from "../components/layouts/PageLayout/PageLayout";
import Home from "../pages/Home/Home";
import ProtectedRoute from "./ProtectedRoute";
import Assessment from "../pages/Assessment/Assessment";
import { useAppState } from "../AppState";
import { observer } from "mobx-react-lite";
import useFetchMe from "../hooks/useFetchMe";
import CareerMatches from "../pages/CareerMatches/CareerMatches";
import CareerMatch from "../pages/CareerMatch/CareerMatch";
import NotFound from "../pages/NotFound/NotFound";

const Router = () => {
  const location = useLocation();
  const {
    store: { userStore },
  } = useAppState();
  const loading = useFetchMe();
  const isAuth = userStore.isAuth;

  if (loading) return null;

  return (
    <Routes location={location}>
      <Route element={<PageLayout location={location} />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/assessment/:id"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <Assessment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/career-match/:id"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <CareerMatch />
            </ProtectedRoute>
          }
        />
        <Route
          path="/career-match/"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <CareerMatches />
            </ProtectedRoute>
          }
        />{" "}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default observer(Router);
