import { memo, SVGProps } from "react";

const LikeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24" cy="24" r="24" fill="#F0F5F3" />
      <path
        d="M15.4277 20.9151C15.4275 19.6725 15.9269 18.482 16.8136 17.6115C17.7002 16.741 18.8997 16.2635 20.142 16.2865C21.614 16.2787 23.0186 16.9029 23.9992 18.0008C24.9797 16.9029 26.3843 16.2787 27.8563 16.2865C29.0987 16.2635 30.2981 16.741 31.1848 17.6115C32.0714 18.482 32.5708 19.6725 32.5706 20.9151C32.5706 25.5059 27.1029 28.9722 23.9992 31.7151C20.9023 28.9491 15.4277 25.5094 15.4277 20.9151Z"
        fill="#FD6E03"
      />
    </svg>
  );
};

export default memo(LikeIcon);
