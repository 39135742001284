import React, { useEffect, useState } from "react";
import AccountModalInnerLayout from "../AccountModalInnerLayout/AccountModalInnerLayout";
import Input from "../../../shared/Input/Input";
import Button from "../../../shared/Button/Button";
import { validatePassword } from "../../../../utils/validatePassword";
import { useAppState } from "../../../../AppState";
import PasswordIcon from "../../../../icons/PasswordIcon";
import { AccountSteps } from "../../AccountModal";
import scss from "./styles.module.scss";

type Props = {
  code: string;
  changeStepHandler: (step: AccountSteps) => () => void;
};

type StateProps = {
  password: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
};

const ResetPasswordForm = ({ code, changeStepHandler }: Props) => {
  const {
    service: { authService },
  } = useAppState();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<StateProps>({
    password: "",
    passwordError: "",
    confirmPassword: "",
    confirmPasswordError: "",
  });

  const checkToken = async () => {
    try {
      await authService.checkResetPasswordToken({ code });
    } catch (_) {
      changeStepHandler("INVALID_TOKEN_MESSAGE")();
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStateHandler = (value: string, target: HTMLInputElement) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: value,
      ...(target.name.startsWith("confirm")
        ? { confirmPasswordError: "" }
        : { passwordError: "" }),
    }));
  };

  const submit = async () => {
    if (submitting) return;

    const formattedPassword = state.password.trim();
    const validPassword = validatePassword(formattedPassword);

    if (!validPassword)
      setState((prevState) => ({
        ...prevState,
        passwordError: "Invalid password",
      }));

    const formattedConfirmPassword = state.confirmPassword.trim();
    const validConfirmPassword = validatePassword(formattedConfirmPassword);

    if (formattedPassword !== formattedConfirmPassword) {
      setState((prevState) => ({
        ...prevState,
        passwordError: "Password and Confirm password do not match",
        confirmPasswordError: "Password and Confirm password do not match",
      }));
      return;
    }

    if (!validConfirmPassword)
      setState((prevState) => ({
        ...prevState,
        confirmPasswordError: "Invalid password",
      }));

    if (validConfirmPassword && validPassword) {
      setSubmitting(true);
      await authService.resetPassword({
        code: code,
        password: formattedPassword,
        passwordConfirmation: formattedConfirmPassword,
      });
      changeStepHandler("SUCCESS")();

      setSubmitting(false);
    }
  };

  return (
    <AccountModalInnerLayout
      innerClassName={scss.container}
      title="Reset password"
    >
      <Input
        value={state.password}
        onChange={changeStateHandler}
        placeholder="Password"
        errorText={state.passwordError}
        type="password"
        icon={<PasswordIcon />}
        name="password"
      />
      <Input
        value={state.confirmPassword}
        onChange={changeStateHandler}
        placeholder="Confirm password"
        errorText={state.confirmPasswordError}
        type="password"
        icon={<PasswordIcon />}
        name="confirmPassword"
      />
      <Button
        className={scss.button}
        uppercase
        variant="filled-primary"
        onClick={submit}
      >
        Reset password
      </Button>
    </AccountModalInnerLayout>
  );
};

export default ResetPasswordForm;
