import React, { useCallback } from "react";
import { AssessmentStepDto } from "../../../../../../api/data-contracts";
import QuestionSectionLayout from "../QuestionSectionLayout/QuestionSectionLayout";
import { useAppState } from "../../../../../../AppState";
import RadioButtonRankSelect from "../../../../../../components/shared/RadioButtonRankSelect/RadioButtonRankSelect";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

type Props = {
  step: AssessmentStepDto;
  currentStepIndex: number;
};

const SelectIndustriesRankQuestion = ({ step, currentStepIndex }: Props) => {
  const {
    store: { assessmentStore },
  } = useAppState();

  const onChangeHandler = useCallback(
    (optionId: string, value: number) => {
      assessmentStore.setStepSelectionOptions(currentStepIndex, {
        ...step.selectedOptions,
        [optionId]: { rank: value },
      });
    },
    [currentStepIndex, assessmentStore, step.selectedOptions],
  );

  return (
    <QuestionSectionLayout
      title={step.questionTitle}
      innerClassName={scss.container}
      withSeparator
    >
      {step.options.map((option, i) => {
        return (
          <RadioButtonRankSelect
            key={option.relationId}
            option={option}
            onChange={onChangeHandler}
            maxRank={5}
            // @ts-ignore
            currentRank={step.selectedOptions?.[option.relationId]?.rank}
            infoCaptions={
              !i ? { left: "Least Import.", right: "Most Import." } : undefined
            }
          />
        );
      })}
    </QuestionSectionLayout>
  );
};

export default observer(SelectIndustriesRankQuestion);
